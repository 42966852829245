import { useEffect, useState } from "react";
import { TitleBarHeight } from "./ui/TitleBar";
import Card from "./ui/Card";
import ButtonGroup from "./ui/ButtonGroup";
import Loader from "./ui/Loader";
import QRScan from "./ui/QRScan";
import { useTranslation } from "react-i18next";

class ControllerCls {
    constructor() {
        this.can_callbacks = [];
        this.event_callbacks = [];
        this.comodule_callbacks = [];
        this.socket = null;
        this.reconnect = false;
        this.reconnect_handler = null;
        this.check_id_result = null;
        this.upload_result_answer = null;
        this.state = {
            connected: null,
            firmware: {
                software: '?',
                hardware: '?',
            },
            backend: {
                version: '?',
            },            
        }
        
        setInterval(() => {
            this.sendCanMessage(0x42A, []);
            this.sendCanMessage(0xFE0D, [0], true);
        }, 1000);

        if(process.env.REACT_APP_FORCE_BOX_ID !== undefined) {
            this.connect(process.env.REACT_APP_FORCE_BOX_ID);
        }
    }

    getState() {
        return this.state;
    }

    sendCanMessage(canId, data, isExtended = false) {
        this.sendGenericMessage({
            type: "can_msg",
            id: canId,
            data: data,
            ext: isExtended,
        })
    }

    sendGenericMessage = (msg) => {
        if((this.state.connected === true) && (this.socket !== null)) {
            this.socket.send(JSON.stringify(msg));
        }
    }

    subscribeToCanMessage(callback) {
        this.can_callbacks.push(callback);
    }

    unsubscribeToCanMessage(callback) {
        const index = this.can_callbacks.indexOf(callback);
        if(index > -1) {
            this.can_callbacks.splice(index, 1);
        }
    };

    subscribeToComoduleMessage(callback) {
        this.comodule_callbacks.push(callback);
    }

    unsubscribeToComoduleMessage(callback) {
        const index = this.comodule_callbacks.indexOf(callback);
        if(index > -1) {
            this.comodule_callbacks.splice(index, 1);
        }
    };

    subscribeToEvent(callback) {
        this.event_callbacks.push(callback);
    }

    unsubscribeToEvent(callback) {
        const index = this.event_callbacks.indexOf(callback);
        if(index > -1) {
            this.event_callbacks.splice(index, 1);
        }
    };

    _propagateState() {
        this.event_callbacks.forEach((callback) => {
            callback(this.state);
        });
    }

    close() {
        if(this.socket != null) {
            this.socket.close();
            this.socket = null;
        }
        if(this.reconnect_handler != null) {
            clearTimeout(this.reconnect_handler);
            this.reconnect_handler = null;
        }
        this.state.connected = null;
        this.reconnect = false;
        this._propagateState();
    }

    connect(id) {
        if(this.socket != null) {
            this.close();
        }
        this.state.connected = false;
        this._propagateState();

        this.reconnect = true;
        this.socket = new WebSocket(process.env.REACT_APP_WEBSOCKET_DOMAIN || 'ws://192.168.1.122:8080/dp-box-ws');
        this.socket.addEventListener("open", () => {
            if(this.socket == null) {
                return;
            }
            
            this.socket.send(JSON.stringify({
                type: "hello",
                role: "client",
                box: id,
            }));
          });
        this.socket.addEventListener('message', async (event) => {                     
            const data = JSON.parse(event.data);

            if(data?.type === "can_msg") {
                this.can_callbacks.forEach((callback) => {
                    callback(data);
                });
            }
            else if(data?.type === "comodule_data") {
                this.comodule_callbacks.forEach((callback) => {
                    callback(data.data);
                });            
            }
            else if(data?.type === "firmware_metadata") {
                this.state.firmware.software = data.data.sw_version;
                this.state.firmware.hardware = data.data.hw_version;
                this.state.connected = true;
                this._propagateState();                
            }
            else if(data?.type === "backend_metadata") {
                this.state.backend = data.data;
                this._propagateState();
            }
            else if(data?.type === "check_ids") {
                this.check_id_result = data;
            }
            else if(data?.type === "upload_result") {
                this.upload_result_answer = data;
            }
            else if(data?.type === "can_info") {

            }
            else {
                console.log(data);
            }
        });
        this.socket.addEventListener('close', (event) => {         
            if(this.reconnect) {
                this.state.connected = false;
                this.socket = null;
                this._propagateState();

                this.reconnect_handler = setTimeout(() => {
                    this.reconnect_handler = null;
                    this.connect(id);
                }, 100);
            }
        });   

        this.socket.addEventListener("error", (event) => {
            console.log("WebSocket error: ", event);
        });
    }

    async check_ids(bike_id, frame_id, comodule_id, upload) {
        this.check_id_result = null;
        this.sendGenericMessage({
            type: "check_ids",
            bike_id: bike_id,
            frame_id: frame_id,
            comodule_id: comodule_id,
            upload: upload,
        });

        const start = Date.now();
        while(Date.now() - start < 10000) {
            if(this.check_id_result != null) {
                return this.check_id_result;
            }
            else {
                await new Promise((resolve) => setTimeout(resolve, 100));
            }
        }

        return {
            success: false,
            error: "backendNotResponding"
        }
    }

    async upload_result(upload_data) {
        console.warn(upload_data)
        this.upload_result_answer = null;
        this.sendGenericMessage({
            type: "upload_result",
            upload_data: upload_data,
        });

        const start = Date.now();
        while(Date.now() - start < 10000) {
            if(this.upload_result_answer != null) {
                return this.upload_result_answer;
            }
            else {
                await new Promise((resolve) => setTimeout(resolve, 100));
            }
        }

        return {
            success: false,
            error: "backendNotResponding"
        }
    }
};

export const Controller = new ControllerCls();


const NotConnectedModalContainerStyle = {
    position: "fixed",
    top: TitleBarHeight,
    zIndex: 1500,
    bottom: 0,
    backdropFilter: "blur(2px) contrast(60%)",
    left: 0,
    right: 0,
    display: "flex",
}

const NotConnectedModalStyle = {
    margin: "auto",
    width: "90vw",
    maxHeight: "100%",
}

export function ControllerViewer(props) {
    const [state, setState] = useState(Controller.getState());
    const {t} = useTranslation();

    useEffect(() => {    
        const controllerEvent = (state) => {
            setState(() => { return {...state}});
        }
    
        Controller.subscribeToEvent(controllerEvent);
        
        return () => {
          Controller.unsubscribeToEvent(controllerEvent);      
        };
    });

    let modal = null;

    if(state.connected === null) {     
        const parseResult = (result) => {
            console.log(result)
            const sp = result.split(":");
            if(sp.length === 2) {
                if(sp[0] === "DPBOX") {
                    Controller.connect(sp[1]);
                }
            }
        }

        modal = (
            <div style={NotConnectedModalContainerStyle}>
                <div style={NotConnectedModalStyle}>
                    <Card title={t("scanBox")} alertTitle>
                        <QRScan onResult={parseResult} />
                    </Card>
                </div>
            </div>
        );
    }
    else if(state.connected === false) {
       modal = (
            <div style={NotConnectedModalContainerStyle}>
            <div style={NotConnectedModalStyle}>
                <Card title={t("connecting")}>
                    <div style={{display: "flex", gap: "1em", flexDirection: "column", justifyContent: "center", alignItems: "center", margin: "1em", minWidth: "200px"}}>
                        <Loader />
                        <ButtonGroup labels={[{render: t("cancel"), danger: true, onClick: () => {Controller.close()}}]} />
                    </div>
                </Card>
            </div>
        </div>
       );
    }
    
    return (            
        <div>
            {props.children}
            {modal}
        </div>
    ); 
}
