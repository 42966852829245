import { EmphFont, primaryColor, secondaryColor } from "./Styles";

export const TabSelectionHeight = "68px";

const TabSelectionStyle = {  
  display: 'flex',
  height: TabSelectionHeight,
  position: "fixed",
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 1000,
  background: primaryColor,
  paddingTop: "2px",
}

const TabSelectionPageStyle = {
  backgroundColor: secondaryColor,
  color: primaryColor,
  padding: "auto",
  display: "grid",
  gridTemplateRows: "auto min-content",
  width: "100%",
  height: "100%",
  cursor: "pointer",
}

const TabSelectionPageTitleStyle = {
  margin: "auto",
  paddingBottom: "0.5em",
  ...EmphFont,
}

const TabSelectionPageIconStyle = {
  fontSize: "1.5em",
  margin: "auto",
}

const TabSelectionPageActiveStyle = {
  backgroundColor: primaryColor,
  color: secondaryColor,
}


const TabSelection = (props) => {
  return (
    <div style={TabSelectionStyle}>
      {props.children}
    </div>
  );
}

export const TabSelectionPage = (props) => {
  let style = TabSelectionPageStyle;

  if(props.active) {
    style = {...style, ...TabSelectionPageActiveStyle};
  }

  return (
    <div style={style} onClick={props.onClick}>
      <span style={TabSelectionPageIconStyle}>{props.icon}</span>
      <span style={TabSelectionPageTitleStyle}>{props.title}</span>
    </div>
  );
}

export default TabSelection;
