import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import Card from "../ui/Card";
import { CardBackgroundAlertStyle, ManualCardInfoErrorStyle, ManualCardInfoLeftStyle, ManualCardInfoRightStyle, ManualCardStyle, ManualCardTitleStyle } from "../templates/Manual";
import Separator from "../ui/Separator";
import ActionList from "../ui/ActionList";
import { Controller } from "../Controller";
import { useTranslation } from "react-i18next";

class LockCls {
    constructor() {
        this.state = '';
        this.firmwareVersion = '';
        this.hardwareVersion = '';
        this.errors = [];
        this.lastPacket = 0;
        this.bootloaderisActive = false;
        this.done = false;

        this.reset();
        setInterval(() => this.checkState(), 100);
        Controller.subscribeToCanMessage((can) => this.parseCanMessage(can));
    }

    checkState() {
        if(Date.now() - this.lastPacket > 2500) {
            this.reset();
        }
    }

    reset() {
        this.state = 'offline';
        this.firmwareVersion = '?';
        this.hardwareVersion = '?';
        this.bootloaderisActive = false;
        this.errors = [];
        this.done = false;
    }

    getState() {
        return {
            state: this.state,
            firmwareVersion: this.firmwareVersion,
            hardwareVersion: this.hardwareVersion,
            bootloaderisActive: this.bootloaderisActive,
            errors: this.errors,
            done: this.done,
        }
    }

    resetDoneFlag() {
        this.done = false;
    }

    setLock(enable) {
        if(enable) {
            Controller.sendCanMessage(0x201, [0x00]);
        }
        else {
            Controller.sendCanMessage(0x201, [0x01]);
        }
    }

    setBootloader(enable) {
        if(enable) {
            Controller.sendCanMessage(0x201, [0x1A]);
        }
        else {
            Controller.sendCanMessage(0x201, [0x1F]);
        }
    }

    parseCanMessage(data) {
        if(data.id === 0x181) {
            this.lastPacket = Date.now();

            if(this.state === 'offline') {
                this.state = 'online';
            }

            if(data.data[0] & 0x01) {
                this.done = true;
            }
        }
        else if (data.id === 0x281) {
            this.lastPacket = Date.now();

            if(this.state === 'offline') {
                this.state = 'online';
            }

            //decode data
            const state = data.data[0];
            const isLock = state & 0x01;
            const isStatusInError = state & 0x02;
            const isHBridgeInError = state & 0x04;
            const isBootloaderActive = !(state & 0x10);

            const hardwareVersion = data.data[2];
            const firmwareVersion = data.data[3];
            this.hardwareVersion = (hardwareVersion & 0x07) + "." + ((hardwareVersion & 0xF8) >> 3);
            this.firmwareVersion = (firmwareVersion & 0x07) + "." + ((firmwareVersion & 0xF8) >> 3);

            //decode errors
            const errors = [];
            if(isStatusInError) {
                errors.push("Pin status error");
            }
            if(isHBridgeInError) {
                errors.push("HBridge error");
            }
            //fill status
            this.errors = errors;
            if(errors.length === 0) {
                this.state = isLock ? 'Locked' : 'Unlocked';
            }
            else {
                this.state = "error";
            }
            this.bootloaderisActive = isBootloaderActive;
        }
    }
}

const lock = new LockCls();
export default lock;


export function LockViewer () {
    const [lockState, setlockState] = useState(lock.getState());
    const {t} = useTranslation();

    useEffect(() => {    
        const handler = setInterval(() => {
            setlockState(() => lock.getState());
        }, 250);
        
        return () => {
            clearInterval(handler);
        };
    });

    const alert = (lockState.state === "error") || (lockState.state === "offline");

    const actions = [{
        title: t("lock.motor"),
        buttons: [
            {render: t("lock.lock"), selected: lockState.state === "Locked",         onClick: () => {lock.setLock(true)}},
            {render: t("lock.unlock"), selected: lockState.state === "Unlocked",     onClick: () => {lock.setLock(false)}},
        ]
    },{
        title: t("lock.bootloader"),
        buttons: [
            {render: t("lock.enable"), selected: lockState.bootloaderisActive,      onClick: () => {lock.setBootloader(true)}},
            {render: t("lock.disable"), selected: !lockState.bootloaderisActive,    onClick: () => {lock.setBootloader(false)}},
        ]
    }]

    let titleStyle = ManualCardTitleStyle;
    let errorStyle = ManualCardInfoErrorStyle;
    if(alert) {
        titleStyle = {...titleStyle, ...CardBackgroundAlertStyle};
        errorStyle = {...errorStyle, ...CardBackgroundAlertStyle};
    }

    let error;
    if(lockState.errors.length > 0) {
        error = (
            <div style={errorStyle}>
                {lockState.errors.map((item) => t('lock.errors.' + item)).join(', ')}
            </div>
        );
    }

    return (
        <Card title={<span><FontAwesomeIcon icon="fa-solid fa-unlock" />&nbsp;&nbsp;{t("lock.lockModule")}</span>} >
            <div style={ManualCardStyle}>
                <div style={titleStyle}>{t('lock.state.' + lockState.state)}</div>        
               {error}
                <div style={ManualCardInfoLeftStyle}>
                    <span>{t("lock.bootloader")}: {lockState.bootloaderisActive ? t("lock.enabled") : t("lock.disabled")}</span>
                </div>
                <div style={ManualCardInfoRightStyle}>
                    <span>{t("lock.firmware")}: {lockState.firmwareVersion}</span>
                    <span>{t("lock.hardware")}: {lockState.hardwareVersion}</span>
                </div>

                <div style={{gridColumn: "1 / 3", gridRow: "4"}}>
                    <Separator title={t("actions")}/>
                    <ActionList labels={actions} />
                </div>                
            </div>
        </Card>
    );
};
  