import { primaryColor } from "./Styles";
import pacakge from "../../package.json";
import { useEffect, useState } from "react";
import { Controller } from "../Controller";

function Version(props) {
   const [state, setState] = useState(Controller.getState());
   useEffect(() => {    
         const controllerEvent = (state) => {
            setState(() => { return {...state}});
         }
   
         Controller.subscribeToEvent(controllerEvent);
         
         return () => {
         Controller.unsubscribeToEvent(controllerEvent);      
         };
   });
  
   return (
      <div style={{display: "flex", justifyContent: "end", fontSize: "small", gap: "1em", color: primaryColor, opacity: 0.3}}>
         <span>Frontend: v{pacakge.version}</span>
         <span>Backend: v{state.backend.version}</span>
         <span>Firmware: v{state.firmware.software}/{state.firmware.hardware}</span>
      </div>
   );
 
}

export default Version;
