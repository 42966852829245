import { primaryColor, secondaryColor } from "./Styles";

function Separator(props) {

  return (
    <div style={SeparatorStyle}>
      <div style={SeparatorLineStyle}></div>
      <div style={SeparatorTitleContainerStyle}>
        <span style={SeparatorTitleStyle}>{props.title}</span>
      </div>      
    </div>
  );
}

const SeparatorStyle = {
  position: "relative",
  marginTop: "1em",
  marginBottom: "1em",
}

const SeparatorLineStyle = {
  width: "75%",
  borderBottom: "1px solid " + primaryColor,
  height: "1px",
  margin: "auto",
  paddingTop: "0.5em",
}


const SeparatorTitleContainerStyle = {  
  height: "1em",
  position: "absolute",
  zIndex: "1",
  top: "0",
  display: "flex",
  width: "100%",
}

const SeparatorTitleStyle = {
  margin: "auto",
  textAlign: "center",
  backgroundColor: secondaryColor,
  paddingLeft: "1em",
  paddingRight: "1em",
}

export default Separator;
