import ASI from "../devices/ASI";
import QRScan, { video_size_calc } from "../ui/QRScan";
import { Test, TestAdvices } from "./Test";
import Input from "../ui/Input";
import YesNoToggle from "../ui/YesNoToggle";
import comodule from "../devices/comodule";
import { Controller } from "../Controller";
import { TurnBatteryOn } from "./Battery";
import { ASITurnOn } from "./ASI";
import Camera, { FACING_MODES, IMAGE_TYPES }  from 'react-html5-camera-photo';
import { leftPad } from "../utils";
import { TEST_LIST_ASSEMBLY, TEST_LIST_MAINTENANCE } from "./TestList";

const FAKE_BIKE = false;

let LAST_FRAME_ID = null;

class BikeCls extends Test {
    getModule() {
        return "Bike";
    }
}

class BikeScanQrcodeCls extends BikeCls {
    constructor() {
        super();
        this.id = null;
    }

    async run(forceUpdate) {
        if (FAKE_BIKE) {
            this.id = "E00testdamien";
        }

        await this.regularCheck(Infinity, async () => {
            if(this.id !== null) {
                this.result = {key: "notranslate", data: {value: this.id}};
                return true;
            }

            return new Error("bike.noQrCode");
        });
    }

    reset() {
        super.reset();
        this.id = null;
    }

    getModal(translate) {
        const parseResult = (result) => {
            console.log(result)
            const sp = result.split("=");
            if(sp.length === 2) {
                this.id = sp[1];
            }
        }
        return (
            <div>
                <h1 style={{fontSize: "medium"}}>{translate("bike.scanBikeQrCode")} : </h1>
                <QRScan onResult={parseResult} />
            </div>
        );
    }

    getName() {
        return "bike.scanQrCode";
    }
}

class BikeGetFrameIdCls extends BikeCls {
    constructor(preset) {
        super();
        this.id = null;
        this.preset = preset;
    }

    reset() {
        super.reset();
        this.id = null;
    }

    async run(forceUpdate) {
        if (FAKE_BIKE) {
            this.result = {key: "notranslate", data: {value: "VIRTUAL_FRAME_E002321"}};
            return;
        }

        await this.regularCheck(Infinity, async () => {
            this.result = {key: "notranslate", data: {value: this.id}};
            if(this.id !== null) {
                return true;
            }

            return new Error("bike.noFrameId");
        });
    }

    getModal(translate) {
        const parseResult = (result) => {
            const r = result.trim();
            if(r.length > 0) {
                if(this.preset) {
                    this.id = this.preset + leftPad(r, "DxxTxxxx".length - this.preset.length);
                }
                else {
                    this.id = r;
                }

                LAST_FRAME_ID = this.id;
            }
        }

        const parseQrResult = (result) => {
            if(!result.startsWith("http")) {
                this.id = result;
                LAST_FRAME_ID = result;
            }
        }
      

        if(this.preset) {
            return (
                <div>
                    <h1 style={{fontSize: "medium"}}>{translate("bike.enterBikeFrameIDLastDigits")} : </h1>
                    <span style={{display: "flex"}}><span style={{marginTop: "auto", marginBottom: "auto", marginRight: "0.5em"}}>{this.preset}</span><Input onResult={parseResult} input_type="tel" placeholder={"DxxTxxxx".slice(this.preset.length)} /></span>
                    <QRScan onResult={parseQrResult} />
                </div>
            );
        }
        else {
            return (
                <div>
                    <h1 style={{fontSize: "medium"}}>{translate("bike.enterBikeFrameID")} : </h1>
                    <Input onResult={parseResult} placeholder="DxxTxxxx" />
                    <QRScan onResult={parseQrResult} />
                </div>
            );
        }
    }

    getName() {
        return "bike.frameID";
    }
}

class BikeGetFrameIdPictureCls extends BikeCls {
    constructor() {
        super();
        this.picture = null;
    }

    async run(forceUpdate) {
        await this.regularCheck(Infinity, async () => {
            if(this.picture !== null) {
                this.result = "OK";
                return true;
            }

            return new Error("Cancelled");
        });
    }

    reset() {
        super.reset();
        this.picture = null;
    }

    getModal(translate) {
        const parseResult = (result) => {
            const split = result.split(";");
            const r = {
                mime: split[0].split(":")[1],
                base64: split[1].split(",")[1],
            }
            this.picture = r;
        }
        return (
            <div>
                <h1 style={{fontSize: "medium"}}>{translate("bike.scanFrameId")} : </h1>
                <div style={{maxHeight: video_size_calc, maxWidth: video_size_calc, margin: 'auto'}}>
                    <Camera 
                        isMaxResolution
                        isSilentMode 
                        idealFacingMode={FACING_MODES.ENVIRONMENT}
                        onTakePhoto={(dataUri) => { parseResult(dataUri); }}
                        onCameraError={(error) => {  } }
                        imageType={IMAGE_TYPES.PNG}
                    />
                </div>
            </div>
        );
    }

    getName() {
        return "bike.frameIDPicture";
    }
}

class BikeGetComoduleIdCls extends BikeCls {
    constructor() {
        super();
        this.id = null;
    }

    reset() {
        super.reset();
        this.id = null;
    }

    async run(forceUpdate) {
        await this.regularCheck(30000, async () => {
            const state = comodule.getState();

            if(state.id !== '?') {
                this.id = state.id;
                this.advices = [];
                this.result = {key: "notranslate", data: {value: state.id}};
                return true;
            }

            this.advices = [TestAdvices.checkComoduleConnection];
            return new Error("comoduleNoAnswer");
        });
    }

    getModal(translate) {
        return (
            <div>
                <h1 style={{fontSize: "medium"}}>{translate("bike.comoduleShakeToWakeUp")}</h1>                
            </div>
        );
    }

    getName() {
        return "bike.comoduleID";
    }

    isTestValid() {
        return !((GetComoduleID.result === "comoduleNoAnswer") || (GetComoduleID.result === "Cancelled"));
    }
}

class BikeSetComoduleIdCls extends BikeCls {
    async run(forceUpdate) {
        this.fail = this.result === "N/A";       
        comodule.setId(this.result); 
    }

    setId(id) {
        this.result = id;
    }

    getName() {
        return "bike.comoduleID";
    }
}


class FrontLightCls extends BikeCls {
    constructor() {
        super();
        this.front_light = null;
    }
    
    reset() {
        super.reset();
        this.front_light = null;
    }
        
    async run(forceUpdate) {
        if(!TurnBatteryOn.isTestValid() || !ASITurnOn.isTestValid()) {
            this.fail = true;
            this.result = "Skipped";
            return;
        }
        
        let last_toogle = Date.now();
        comodule.reset();
        ASI.setHeadlight(true);

        await this.regularCheck(Infinity, async () => {
            const state = ASI.getState();

            const delta = Date.now() - last_toogle;
            if(delta > 499) {
                ASI.setHeadlight(!state.headlight);
                last_toogle = Date.now();
            }

            if(state.errors.length > 0) {
                this.fail = true;
                this.advices = [TestAdvices.checkASIConnection];
                return new Error("ASI errors: " + state.errors.join(", "));
            }

            if(this.front_light !== null) {
                if(this.front_light) {
                    this.fail = false;
                    this.advices = [];
                    this.result = "OK";                    
                }
                else {
                    this.fail = true;
                    this.advices = [TestAdvices.checkFrontLightConnection];
                    this.result = "bike.frontLightNotWorking";
                }
                return true;
            }
            else {
                this.fail = true;
                this.advices = [TestAdvices.checkFrontLightConnection];
                this.result = "bike.frontLightNotWorking";
                return false;
            }
        })
        return true;
    }

    getModal(translate) {
        const setFront = (value) => {
            this.front_light = value;
        };

        return (
            <div>
                <h1 style={{fontSize: "medium"}}>{translate("bike.isFrontLightBlinking")}</h1>
                <YesNoToggle default={this.front_light} onChange={setFront}/>
            </div>
        );
    }

    getName() {
        return "bike.checkFrontLight";
    }
}


class RearLightCls extends BikeCls {
    constructor() {
        super();
        this.rear_light = null;
    }
    
    reset() {
        super.reset();
        this.rear_light = null;
    }
        
    async run(forceUpdate) {
        if(!TurnBatteryOn.isTestValid()) {
            this.fail = true;
            this.result = "Skipped";
            return;
        }

        await this.regularCheck(Infinity, async () => {
            if(this.rear_light !== null) {
                if( this.rear_light) {
                    this.fail = false;
                    this.advices = [];
                    this.result = "OK";                    
                }
                else {                  
                    this.fail = true;
                    this.advices = [TestAdvices.checkRearLightConnection];
                    this.result = "bike.rearLightNotWorking";                
                }
                return true;
            }
            else {
                this.fail = true;
                this.result = "bike.rearLightNotWorking";
                this.advices = [TestAdvices.checkRearLightConnection];
                return false;
            }
        })
        return true;
    }

    getModal(translate) {
        const setRear = (value) => {
            this.rear_light = value;
        };

        return (
            <div>
                <h1 style={{fontSize: "medium"}}>{translate("bike.isRearLightActive")}</h1>
                <YesNoToggle default={this.back_light} onChange={setRear}/>
                <br />
            </div>
        );
    }

    getName() {
        return "bike.checkRearLight";
    }
}


class BikeCheckIdDefinitionCls extends BikeCls {
    async run(forceUpdate) {
        if((ScanQrCode.id === null) || (GetFrameID.id === null) || !GetComoduleID.isTestValid()) {
            this.fail = true;
            this.result = "Skipped";
            return;
        }
        
        let result = await Controller.check_ids(ScanQrCode.id, GetFrameID.id, GetComoduleID.result, false);
        if(result.success !== true) {
            this.fail = true;
            this.result = {key: result.error, data: {target: result.target}};

            this.advices = [TestAdvices.checkIdDefinition];
            return;
        }
        else {
            this.result = "OK";
            return;
        }              
    }

    getName() {
        return "bike.verifyIDs";
    }
}



class BikeUploadIdsCls extends BikeCls {
    async run(forceUpdate) {
        if((ScanQrCode.id === null) || (GetFrameIDPicture.picture === null) || !GetComoduleID.isTestValid()) {
            this.fail = true;
            this.result = "Skipped";
            console.log(ScanQrCode.id, GetFrameIDPicture.picture, GetComoduleID.result);
            return;
        }

        const picture = {...GetFrameIDPicture.picture};
        picture.frame_id = LAST_FRAME_ID;
        
        let result = await Controller.check_ids(ScanQrCode.id, picture, GetComoduleID.id, true);
        if(result.success !== true) {
            this.fail = true;
            this.result = {key: result.error, data: {target: result.target}};
            return;
        }
        else {
            this.result = "OK";
            return;
        }              
    }

    getName() {
        return "bike.uploadIDs";
    }
}

class BikeUploadResultsCls extends BikeCls {
    constructor(type) {
        super();
        this.type = type;
    }

    async run(forceUpdate) {
        let test_list = [];
        if(this.type === 'maintenance') {
            test_list = TEST_LIST_MAINTENANCE;
        }
        else {
            test_list = TEST_LIST_ASSEMBLY;
        }
        
        const data_result = test_list.map((test) => {
            let result = test.result;
            if(result.key === "notranslate") {
                result = result.data.value;
            }
            return {
                name: test.getName(),
                result: result,
            }
        });


        if(ScanQrCode.id === null) {
            this.fail = true;
            this.result = "Skipped";
            return;
        }

        let filename = ScanQrCode.id + ".json";

        if(this.type === 'maintenance') {
            filename = data_result[0].result + '_' + data_result[1].result + '_' + data_result[2].result;
        }
        else {
            filename = data_result[0].result + '_' + data_result[1].result + '_' + data_result[2].result;          
        }

        const upload_data = {
            bike_id: ScanQrCode.id,
            filename: filename,
            data: data_result,
            date: Date.now(),
            type: this.type,
        }
        
        let result = await Controller.upload_result(upload_data);
        if(result.success !== true) {
            this.fail = true;
            this.result = {key: result.error, data: {target: result.target}};
            return;
        }
        else {
            this.result = "OK";
            return;
        }              
    }

    getName() {
        return "bike.uploadIDs";
    }
}


export const ScanQrCode = new BikeScanQrcodeCls();
export const GetFrameID = new BikeGetFrameIdCls();
export const GetFrameIDWithPreset = (preset) => new BikeGetFrameIdCls(preset);
export const GetFrameIDPicture = new BikeGetFrameIdPictureCls();
export const GetComoduleID = new BikeGetComoduleIdCls();
export const SetComoduleID = new BikeSetComoduleIdCls();
export const BikeCheckIdDefinition = new BikeCheckIdDefinitionCls();
export const BikeUploadIds = new BikeUploadIdsCls();
export const FrontLight = new FrontLightCls();
export const RearLight = new RearLightCls();
export const BikeUploadMaintenanceTestResult = new BikeUploadResultsCls('maintenance');
export const BikeUploadAssemblyTestResult = new BikeUploadResultsCls('assembly')