const LANG_EN = {
    //tab titles
    "manual": "Manual",
    "automatic": "Automatic",
    "can_viewer": "CAN Viewer",

    //controller
    "scanBox": "Scan your mainteannce box",
    "connecting": "Connecting...",

    //actions
    "cancel": "Cancel",
    "OK": "OK",
    "Yes": "Yes",
    "No": "No",
    "actions": "Actions",

    //can viewer
    "CANMessage": "CAN Message",

    //manuals
    "asi": {
        "state": "State",
        "enable": "Enable",
        "disable": "Disable",
        "offline": "Offline",
        "assistLevel": "Assist level",
        "frontLight": "Front light",
        "speed": "Speed",
        "pedal": "Pedal",
        "motor": "Motor",
        "wheel": "Wheel",
        "errors": {
            "Ctrl overvoltage": "Ctrl overvoltage",
            "Phase overcurrent": "Phase overcurrent",
            "Current sensor calib.": "Current sensor calib.",
            "Current sensor overcurrent": "Current sensor overcurrent",
            "Ctrl overtemperature": "Ctrl overtemperature",
            "Motor hall sensor": "Motor hall sensor",
            "Ctrl undervoltage": "Ctrl undervoltage",
            "POST static gating test": "POST static gating test",
            "Network com timeout": "Network com timeout",
            "Inst. phase overcurrent": "Inst. phase overcurrent",
            "Motor overtemperature": "Motor overtemperature",
            "Throttle voltage": "Throttle voltage",
            "Inst. ctrl overvoltage": "Inst. ctrl overvoltage",
            "Internal error": "Internal error",
            "Post dynamic gating test": "Post dynamic gating test",
            "Inst. undervoltage": "Inst. undervoltage",
            "Param CRC": "Param CRC",
            "Current scaling": "Current scaling",
            "Voltage scaling": "Voltage scaling",
            "Headlight undervoltage": "Headlight undervoltage",
            "Reserved": "Reserved",
            "CAN": "CAN",
            "Hall stall": "Hall stall",
            "Bootloader": "Bootloader",
            "Param 2 CRC": "Param 2 CRC",
            "Hall vs. sensorless": "Hall vs. sensorless",
            "Dyn. torque sensor voltage": "Dyn. torque sensor voltage",
            "Dyn. torque static voltage": "Dyn. torque static voltage",
            "Remote CAN fault": "Remote CAN fault",
            "Flash code 4,6": "Flash code 4,6",
            "Flash code 4,7": "Flash code 4,7",
            "Flash code 4,8": "Flash code 4,8",
        },
    },

    "battery": {
        "state": "State",
        "on": "On",
        "off": "Off",
        "SOC": "SOC",
        "SN": "SN",
        "temperature": "Temperature",
        "battery": "Battery",
    },

    "comodule": {
        "IOTModule": "IOT Module",
        "state": "State",
        "lock": "Lock",
        "unlock": "Unlock",
        "ID": "ID",
        "lockModule": "Lock",
        "SOC": "SOC",
        "cellular": "Cellular",
        "Firmware": "Firmware",
        "errors": {
            "Battery error": "Battery error",
            "ASI error": "ASI error",
            "Lock error": "Lock error",
            "Assist level change error": "Assist level change error",
        },
    },

    "lock": {
        "motor": "Motor",
        "lock": "Lock",
        "lockModule": "Lock",
        "unlock": "Unlock",
        "bootloader": "Bootloader",
        "enable": "Enable",
        "disable": "Disable",
        "firmware": "Firmware",
        "hardware": "Hardware",
        "enabled": "Enabled",
        "disabled": "Disabled",
        "state": {
            "online": "online",
            "offline": "offline",
            "Locked": "Locked",
            "Unlocked": "Unlocked",
            "error": "error",
        },
        "errors": {
            "Pin status error": "Pin status error",
            "HBridge error": "HBridge error",
        },
    },


    "auto": {
        "start": "START",
        "stop": "STOP",
        "stopping": "STOPPING...",
        "Cancel": "Cancel",
        "Cancelled": "Cancelled",
        "Skipped": "Skipped",
        "notranslate": "{{value}}",

        "test": "Test",
        "success": "Success",
        "failure_one": "{{count}} failure",
        "failure_other": "{{count}} failures",
        "results": "Results",
        "OK": "OK",
        "N/A": "N/A",

        "Bike": "Bike",
        "Scan QRcode": "Scan QRcode",
        "Comodule ID": "Comodule ID",
        "ASI": "ASI",
        "Lights": "Lights",
        "Enable": "Enable",
        "Check motor": "Check motor",
        "Battery": "Battery",
        "Set off": "Set off",
        "Set off again": "Set off again",
        "Set on": "Set on",
        "Verify IDs": "Verify IDs",
        "Frame ID": "Frame ID",
        "Buzzer": "Buzzer",
        "Comodule": "Comodule",
        "Lock": "Lock",
        "Check heartbeat":  "Check heartbeat",
        "Lock sequence": "Lock sequence",
        "Lock sequence again": "Lock sequence again",
        "Unlock sequence": "Unlock sequence",
        "Unlock sequence again": "Unlock sequence again",
        "Check lock bit": "Check lock bit",
        "Check unlock bit": "Check unlock bit",

        "comoduleNoAnswer": "No answer from comodule",
        "NO_QR_CODE": "Qrcode {{target}} not found",
        "ERR_FRAME_ID": "Frame id mismatch. Expected: {{target}}",
        "ERR_COMODULE_ID": "Comodule id mismatch. Expected: {{target}}",
        "ERR_UPLOAD": "Unable to upload the file",
        
        "bike": {
            "noQrCode": "No Qrcode scanned",
            "scanBikeQrCode": "Scan Bike QRcode",
            "scanQrCode": "Scan QRcode",
            "noFrameId": "No frame ID",
            "frameID": "Frame ID",
            "frameIDPicture": "Frame ID picture",
            "enterBikeFrameID": "Enter or scan bike frame ID",
            "enterBikeFrameIDLastDigits": "Enter or scan bike frame ID last digits",
            "comoduleID": "Comodule ID",
            "comoduleShakeToWakeUp": "Shake the bike to wake the comodule",
            "verifyIDs": "Verify IDs",
            "backendNotResponding": "No response from backend",
            "scanFrameId": "Take picture of the frame ID",
            "uploadIDs": "ID uploading",

            "checkFrontLight": "Front light",
            "isFrontLightBlinking": "Is front light blinking :",
            "frontLightNotWorking":  "Front light is not working",
            "checkRearLight": "Rear light",
            "isRearLightActive": "Is rear light active :",
            "rearLightNotWorking":  "Rerar light is not working",
        },

        "battery": {
            "battery": "Battery",
            "invalidState": "State is {{got}} instead of {{expected}}",
        },

        "lock": {
            "invalidState": "Unexpected state '{{got}}'",
            "notFinished": "Lock has not finished",
            "errors": "Errors: ",
            "error_list": {
                "Pin status error": "Pin status error",
                "HBridge error": "HBridge error",
            },
        },

        "comodule": {
            "offline": "Comodule is offline",
            "invalidHeartbeat": "Lock heartbeat is {{value}}",
            "invalidStateInformation": "State information is {{value}}",
            "invalidPowerOn": "Power on is {{value}}",
            "invalidBatteryState": "Battery state is {{value}}",
            "batteryError": "Battery is in error",
            "lockError": "Lock is in error",
            "ASIError": "ASI is in error",
            "invalidLockState": "Lock state is {{value}}",
            "invalidASIState": "ASI state is {{value}}",
            "invalidASIAssist": "ASI assist level is {{value}}",
            "invalidASILight": "ASI light status is {{value}}",
            "badBuzzer": "Buzzer is not working",
            "isBuzzerActive": "Is the buzzer active :",

            "errors": "Errors: ",
            "error_list": {
                "Battery error": "Battery error",
                "ASI error": "ASI error",
                "Lock error": "Lock error",
                "Assist level change error": "Assist level change error",
            },
        },

        "asi": {
            "invalidState": "State is {{value}}",
            "notOnline": "ASI is not online",
            "notWorking": "ASI is not working",
            "motorNotWorking": "Motor is not working",
            "turnPedalUntilMotorStarts": "Turn the pedal until the motor starts"
        },

        "advices": {
            "header": "Some tests have failed. Here are some advices to fix them : ",
            "checkIdDefinition": "Verify the association between the bike and the comodule",
            "changeBatteryOrCheckPowerCables": "Change the battery or check the power cables",
            "changeBatteryOrCheckPowerCablesOrCheckCanCables": "Change the battery, check the power cables or check the CAN cables",
            "checkComoduleConnection": "Check the comodule CAN cable",
            "checkASIConnection": "Check the ASI CAN and power cables",
            "checkFrontLightConnection": "Check the front light cable",
            "checkRearLightConnection": "Check the rear light cable",
            "checkLockMotorConnection": "Check the lock motor cable",
            "checkLockConnection": "Check the lock CAN cable",
            "checkBuzzerConnection": "Check the buzzer cable",
            "checkASIPedalConnection": "Check the ASI pedal cable",
            "checkASIMotorConnection": "Check the ASI motor cable",
        },

        "conf": {
            "title": "Configuration",
            "testSelection": "Test list selection :",
            "maintenance": "Maintenance",
            "assembly": "Assembly",
        },        
    },
}

export default LANG_EN;