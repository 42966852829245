import { memo, useCallback, useState } from "react";
import { TEST_LIST_MAINTENANCE, TEST_LIST_ASSEMBLY } from "../tests/TestList";
import ButtonGroup from "../ui/ButtonGroup";
import Card from "../ui/Card";
import { useTranslation } from "react-i18next";
import Version from "../ui/Version";
import { TabSelectionHeight } from "../ui/TabSelection";
import { alertColor, primaryColor, secondaryColor } from "../ui/Styles";

const AutomaticStyle = {
  display: "flex",
  flexDirection: "column",
  gap: "1em",
}

const AutomaticFooterStyle ={
  backgroundColor: primaryColor,
  color: secondaryColor,
  position: "sticky",
  marginTop: "1em",
  marginBottom: "-1em",
  marginLeft: "-1em",
  marginRight: "-1em",
  bottom: "calc(" + TabSelectionHeight + " + 2px)",
}

const AutomaticFooterErrorStyle = {
  backgroundColor: alertColor,
  padding: "1em",
}

const AutomaticFooterErrorULStyle = {
  margin: 0,
}

let cancel = null;
let running = null;

const TEST_LISTS = {
  "maintenance": TEST_LIST_MAINTENANCE,
  "assembly": TEST_LIST_ASSEMBLY,
}

function getDefaultListName() {
  let saved_name = localStorage.getItem('test_list_name');

  if(TEST_LISTS[saved_name] === undefined) {
    saved_name = "maintenance";
  }

  return saved_name;
}

function AutomaticInner() {
  const [started, setStarted] = useState(false);
  const [testListName, setTestListName] = useState(getDefaultListName());
  const [, updateState] = useState();
  const {t} = useTranslation();
  const forceUpdate = useCallback(() => updateState({}), []);
  const startTest = useCallback(() => {
    setStarted(() => true);
    cancel = null;

    //reset all tests
    TEST_LISTS[testListName]?.forEach((test) => {
      test.reset();
    });

    //create promse chain
    let p = new Promise((resolve, reject) => {
      forceUpdate();
      resolve();
    });
    TEST_LISTS[testListName]?.forEach((test) => {
      p = p.then(async () => {
        running = test;
        if (cancel != null) {
          throw cancel;
        }
        forceUpdate();       
        test.setActive(true); 
        await test.run(forceUpdate);
        test.setActive(false); 
      });
    });

    p = p.then(() => {
      if (cancel != null) {
        throw cancel;
      }
      cancel = false;
      setStarted(() => false);
      forceUpdate();
    });

    p.catch((e) => {
      console.warn(e);
      cancel = false;
      running.setCancelled();
      setStarted(() => false);
    });

  }, [setStarted, forceUpdate, testListName]);
  const stopTest = useCallback(() => {
    cancel = new Error("Cancelled")
    forceUpdate();
  }, [forceUpdate]);

  const btns = [];
  if(started) {
    btns.push({render: (cancel === null) ? t("auto.stop") : t("auto.stopping"), selected: cancel === null, danger: cancel === null, onClick: () => {stopTest()}});
  }
  else {
    btns.push({render: t("auto.start"), selected: true, onClick: () => {startTest()}});
  }

  const changeTestList = (name) => {
    if(started) {
      return;
    }
    localStorage.setItem('test_list_name', name);
    setTestListName(name);
  };
  const btns_cnf = [
    {render: t("auto.conf.maintenance"), disabled: started, selected: testListName === "maintenance", onClick: () => {changeTestList("maintenance")}},
    {render: t("auto.conf.assembly"), disabled: started, selected: testListName === "assembly", onClick: () => {changeTestList("assembly")}},
  ];

  let alert = false;
  let failed = 0;
  let running_cnt = 0;
  let advices = new Set();
  for(let i = 0; i < TEST_LISTS[testListName].length; i++) {
    if(TEST_LISTS[testListName][i].isFailed()) {
      failed += 1;
      alert = true;

      if(!TEST_LISTS[testListName][i].isActive()) {
        TEST_LISTS[testListName][i].getAdvices().forEach((adv) => {
          advices.add(adv);
        });
      }
    }    
    if(TEST_LISTS[testListName][i].isActive()) {
      running_cnt = i;
    }
  }

  let footer;
  if(started) {
    footer = (
      <div style={{display: "flex", justifyContent: "center", margin: "0.5em"}}>
        <span>
        {t("auto.test")} {running_cnt + 1}/{TEST_LISTS[testListName].length}
        </span>
      </div>
    )
  }
  else {
    footer = (
      <div style={{display: "flex", justifyContent: "center", margin: "0.5em"}}>
        <span>
        {t("auto.test")} {TEST_LISTS[testListName].length}/{TEST_LISTS[testListName].length} - {failed > 0 ? t("auto.failure", {count: failed}) : t("auto.success")}
        </span>
      </div>
    )
  }

  const translate = (item) => {
    if(Array.isArray(item)) {
      return item.map((i) => translate(i)).join(' ');
    }
    else if(item.key !== undefined) {
      return t("auto." + item.key, {...item.data});
    }
    else {
      return t("auto." + item);
    }    
  }

  //get all advices
  let render_advices = null;
  if(advices.size > 0) {
    render_advices = (
      <div style={AutomaticFooterErrorStyle}>
        {translate("advices.header")}        
        <ul style={AutomaticFooterErrorULStyle}>
          {
            Array.from(advices).map((item, key) => {
              return <li key={key}>{translate(item)}</li>
            })
          }
        </ul>
      </div>
    );
  }

  return (
    <section style={AutomaticStyle}>  

    <Card title={translate("conf.title")} >
      <div style={{display: "flex"}}>
        <div style={{display: "flex", flexDirection: "column", alignSelf: "center", marginRight: "1em"}}>
          <span>{translate("conf.testSelection")} </span>
          <ButtonGroup labels={btns_cnf} small/>
        </div>      
        <div style={{marginLeft: "auto", alignSelf: "center"}}>
          <ButtonGroup labels={btns} />
        </div>
      </div>
    </Card>
    
    <Card title={t("auto.results")} alertTitle={alert} footer={footer} footerAlert={alert}>
      {
        TEST_LISTS[testListName].map((test, key) => {
          return test.render(key, translate);
        })
      }
      </Card>
      <Version />
            
      <div style={AutomaticFooterStyle}>
        {render_advices}
      </div>
    </section>
  );
}

export default memo(AutomaticInner);
