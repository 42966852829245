import { useEffect, useState } from "react";
import { Controller } from "../Controller";
import Card from "../ui/Card";
import { leftPad } from "../utils";
import { useTranslation } from "react-i18next";

const LoggerStyle = {
  display: 'flex',
  flexDirection: 'column-reverse',
  gap: '1em',
}

const max_items = 50;

let id = 1;

const toDateStr = (timestamp, displayMs) => {
  const date = new Date(timestamp);
  
  const res = leftPad(date.getDate(), 2) +
        "/" + leftPad(date.getMonth()+1, 2) +
        "/" + leftPad(date.getFullYear(), 2) +
        " " + leftPad(date.getHours(), 2) +
        ":" + leftPad(date.getMinutes(), 2) +
        ":" + leftPad(date.getSeconds(), 2);

  if(displayMs) {
    return res + "." + leftPad(date.getMilliseconds(), 3);
  }
  else {
    return res;
  }
};

function Logger() {
  const [messages, setMessages] = useState([]);
  const {t} = useTranslation();

  useEffect(() => {    
    const canMessageReceived = (newMessage) => {
      const data = {
        key: id,
        data: newMessage
      };
      id += 1;

      setMessages(prevMessages => {
        const prevItems = prevMessages.slice(-max_items);
        prevItems.push(data);

        return prevItems;
      });
    }

    Controller.subscribeToCanMessage(canMessageReceived);
    
    return () => {
      Controller.unsubscribeToCanMessage(canMessageReceived);      
    };
  });

  return (
    <section style={LoggerStyle}>
      {
        messages.map((element, id) => {
          const hexdata = leftPad(element.data.id.toString(16), 3) + ' - [' + element.data.data.map((v) => leftPad(v.toString(16), 2)).join(' ') + ']';

          return (
            <Card key={id} title={t("CANMessage")}  info={toDateStr(element.data.ts, true)}>
              <span>{hexdata}</span>
            </Card>
          );
        })
      }
    </section>
  );
}

export default Logger;
