import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import Card from "../ui/Card";
import { CardBackgroundAlertStyle, ManualCardInfoErrorStyle, ManualCardInfoLeftStyle, ManualCardInfoRightStyle, ManualCardStyle, ManualCardTitleStyle } from "../templates/Manual";
import Separator from "../ui/Separator";
import ActionList from "../ui/ActionList";
import { Controller } from "../Controller";
import { leftPad } from "../utils";
import { useTranslation } from "react-i18next";

class ComoduleCls {
    constructor() {
        this.state = '';

        this.soc = '?';
        this.id = '?';
        this.lock_heartbeat = '?';
        this.state_information = '?';
        this.power_on = '?';
        this.errors = [];
        this.gsm_signal_strength = '?';
        this.firmware = '?';

        this.lastPacket = 0;
        this.lastStatePacket = 0;

        this.resetState();
        this.resetCan();
        setInterval(() => this.checkState(), 100);
        Controller.subscribeToCanMessage((can) => this.parseCanMessage(can));
        Controller.subscribeToComoduleMessage((data) => this.parseComoduleStateMessage(data));
    }

    checkState() {
        if(Date.now() - this.lastPacket > 3000) {
            this.resetCan();
        }
        if(Date.now() - this.lastStatePacket > 5000) {
            this.resetState();
        }
    }

    resetState() {
        this.soc = '?';
        this.lock_heartbeat = '?';
        this.state_information = '?';
        this.power_on = '?';
        this.errors = [];
        this.gsm_signal_strength = '?';
        this.firmware = '?';
    }

    resetCan() {
        this.lastPacket = 0;
        this.id = '?';
        this.state = 'offline';        
    }

    getState() {
        return {
            state: this.state,
            soc: this.soc,
            id: this.id,
            start_fault: this.start_fault,
            lock_heartbeat: this.lock_heartbeat,
            state_information: this.state_information,
            power_on: this.power_on,
            errors: this.errors,
            gsm_signal_strength: this.gsm_signal_strength,
            firmware: this.firmware,
        }
    }

    setLock(lock) {
        Controller.sendGenericMessage({
            type: "comodule",
            data: {
                vehiclePowerOn: !lock,
                vehicleSavedAssistMode: 6,
            }
        });
    }

    setId(id) {
        Controller.sendGenericMessage({
            type: "comodule_id",
            data: id,
        });
    }

    buzzer() {
        Controller.sendGenericMessage({
            type: "comodule_command",
            data: {
                command: "vehicleHorn",
            }
        });
    }

    reset() {
        Controller.sendGenericMessage({
            type: "comodule_command",
            data: {
                command: "moduleLogicReset",
            }
        });
    }

    parseComoduleStateMessage(data) {
        if(this.id.toLowerCase() === data.id.toLowerCase()) {
            this.lastStatePacket = Date.now();
            this.id = data.id;
            this.firmware = data.firmwareId + "." + data.firmwareVersion;
            this.state_information = data.vehicleStatesInformation;
            this.start_fault = data.vehicleStartFaults;
            this.lock_heartbeat = data.vehicleLockHeartbeatStatus;
            this.soc = data.moduleBatteryPercentage;
            this.gsm_signal_strength = data.gsmSignalStrength;
            this.power_on = data.vehiclePowerOn;

            const errors = [];
            switch(data.vehicleStartFaults) {
                case 0:
                    break;

                case 1:
                    errors.push("Battery error");
                    break;

                case 2:
                    errors.push("ASI error");
                    break;

                case 3:
                    errors.push("Lock error");
                    break;

                case 4:
                    errors.push("Assist level change error");
                    break;
                
                default:
                    errors.push("Start fault {" + data.vehicleStartFaults + "}");
                    break;
            }        
            this.errors = errors;
        }
    }

    parseCanMessage(data) {      
        if(!data.self) {
            if(data.id === 0x00) {
                this.lastPacket = Date.now();

                if(this.state === 'offline') {
                    this.state = 'online';
                }                        
            }     
            
            if((data.id === 0x1FE) || (data.id === 0x32A) || (data.id === 0x201) || (data.id === 0x121) || (data.id === 0x122)) {
                this.lastPacket = Date.now();

                if(this.state === 'offline') {
                    this.state = 'online';
                }                        
            } 

            if(data.id === 0x42A) {
                this.lastPacket = Date.now();

                if(this.state === 'offline') {
                    this.state = 'online';
                } 

                this.id = data.data.reduce((acc, val) => acc + leftPad(val.toString(16), 2), '');
            }

            if(data.id === 0xFE0A) {
                this.lastPacket = Date.now();
                
                if(this.state === 'offline') {
                    this.state = 'online';
                } 

                this.id = data.data.reduce((acc, val) => acc + leftPad(val.toString(16), 2), '');
            }
        }
    }
}

const comodule = new ComoduleCls();
export default comodule;


export function ComoduleViewer () {
    const [comoduleState, setComoduleState] = useState(comodule.getState());
    const {t} = useTranslation();

    useEffect(() => {    
        const handler = setInterval(() => {
            setComoduleState(() => comodule.getState());
        }, 250);
        
        return () => {
            clearInterval(handler);
        };
    }, [setComoduleState]);

    
    const alert = (comoduleState.errors.length > 0) || (comoduleState.state === "offline");

    const actions = [{
        title: t("comodule.state"),
        buttons: [
            {render: t("comodule.lock"), selected: comoduleState.power_on === false,     onClick: () => {comodule.setLock(true)}},
            {render: t("comodule.unlock"), selected: comoduleState.power_on === true,     onClick: () => {comodule.setLock(false)}},
        ]
    }]
  
    let titleStyle = ManualCardTitleStyle;
    let errorStyle = ManualCardInfoErrorStyle;
    if(alert) {
        titleStyle = {...titleStyle, ...CardBackgroundAlertStyle};
        errorStyle = {...errorStyle, ...CardBackgroundAlertStyle};
    }

    let error;
    if(comoduleState.errors.length > 0) {
        error = (
            <div style={errorStyle}>
                {comoduleState.errors.map((item) => t('comodule.errors.' + item)).join(', ')}
            </div>
        );
    }
  
    return (
        <Card title={<span><FontAwesomeIcon icon="fa-solid fa-tower-broadcast" />&nbsp;&nbsp;{t('comodule.IOTModule')}</span>} >
            <div style={ManualCardStyle}>
                <div style={titleStyle}>{comoduleState.state}</div>        
               {error}
                <div style={ManualCardInfoLeftStyle}>
                    <span>{t('comodule.ID')}: {comoduleState.id}</span>
                    <span>{t('comodule.state')}: {comoduleState.state_information}</span>
                    <span>{t('comodule.lockModule')}: {comoduleState.lock_heartbeat}</span>
                </div>
                <div style={ManualCardInfoRightStyle}>          
                    <span>{t('comodule.SOC')}: {comoduleState.soc} %</span>          
                    <span>{t('comodule.cellular')}: {comoduleState.gsm_signal_strength}</span>
                    <span>{t('comodule.Firmware')}: {comoduleState.firmware}</span>
                </div>

                <div style={{gridColumn: "1 / 3", gridRow: "4"}}>
                    <Separator title={t("actions")}/>
                    <ActionList labels={actions} />
                </div>                
            </div>
        </Card>
    );
};
  