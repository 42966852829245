import ASI from "../devices/ASI";
import { TurnBatteryOn } from "./Battery";
import { Test, TestAdvices } from "./Test";

class TestASICls extends Test {
    getModule() {
        return "ASI";
    }
}

class ASITurnOnCls extends TestASICls {
    async run(forceUpdate) {
        if(!TurnBatteryOn.isTestValid()) {
            this.fail = true;
            this.result = "Skipped";
            return;
        }

        ASI.setEnable(true);

        await this.regularCheck(15000, async () => {
            const state = ASI.getState();

            if(state.errors.length > 0) {
                this.advices = [TestAdvices.checkASIConnection];
                return new Error("Errors: " + state.errors.join(", "));
            }

            if (state.state === "online") {
                this.result = "OK";
                this.advices = [];
                this.fail = false;
                return true;
            }
            else {
                this.advices = [TestAdvices.checkASIConnection];
                this.result = {key: "asi.invalidState", data: {value: state.state}};
                this.fail = true;
                return false;
            }
        })
    }

    getName() {
        return "Enable";
    }

    isTestValid() {
        return this.result === "OK";
    }
}

class ASICheckMotorCls extends TestASICls {
    async run(forceUpdate) {
        if(!TurnBatteryOn.isTestValid() || !ASITurnOn.isTestValid()) {
            this.fail = true;
            this.result = "Skipped";
            return;
        }

        ASI.setEnable(true);
        ASI.setAssistLevel(6);

        let pedial_has_moved = false;
        let pedal_moved_since = null;

        await this.regularCheck(30000, async () => {
            const state = ASI.getState();

            if(state.errors.length > 0) {
                this.result = "Errors: " + state.errors.join(", ");
                this.fail = true;
                return true;
            }

            if (state.state !== "online") {
                this.result = "asi.notOnline";
                this.advices = [TestAdvices.checkASIConnection];
                this.fail = true;
                return true;
            }

            if(state.pedalRPM > 1) {
                pedial_has_moved = true;
                if(pedal_moved_since == null) {
                    pedal_moved_since = Date.now();
                }
            }
            else {
                pedal_moved_since = null;
            }

            if ((state.pedalRPM > 1) && (state.wheelRPM > 15)) {
                this.result = "OK";
                this.fail = false;
                this.advices = [];
                return true;
            }
            else {
                if(pedal_moved_since != null) {
                    if(Date.now() - pedal_moved_since > 3000) {
                        this.result = "asi.motorNotWorking";
                        this.fail = true;
                        this.advices = [TestAdvices.checkASIMotorConnection];
                        return true;
                    }
                }

                if(!pedial_has_moved) {
                    this.advices = [TestAdvices.checkASIPedalConnection];
                }
                else {
                    this.advices = [TestAdvices.checkASIMotorConnection];
                }
            }

            return new Error("asi.notWorking");
        });
    }

    getModal(translate) {
        return (
            <div>
                <h1 style={{fontSize: "medium"}}>{translate("asi.turnPedalUntilMotorStarts")}</h1>                
            </div>
        );
    }

    getName() {
        return "Check motor";
    }
}


export const ASITurnOn = new ASITurnOnCls();
export const ASICheckMotor = new ASICheckMotorCls();
