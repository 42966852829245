import ButtonGroup from "./ButtonGroup";

function ActionList(props) {
  const lines = props.labels.map((label, index, length) => {
    return (
      <div key={index} style={ListContainerStyle}>
        <span style={ActionNameStyle}>{label.title} :</span>
        <span><ButtonGroup labels={label.buttons} /></span>
      </div>
    );
  });

  return (
    <div>
      {lines}
    </div>
  );
};

const ListContainerStyle = {
  display: "grid",
  gridTemplateColumns: "1fr 2fr",
  gridColumnGap: "0.5em",
  gridRowGap: "0.5em",
};

const ActionNameStyle = {
  gridColumn: "1 / 2",
  textAlign: "right",
  verticalAlign: "middle",
  marginTop: "auto",
  marginBottom: "auto",
}

export default ActionList;
