import ButtonGroup from "../ui/ButtonGroup";
import Card from "../ui/Card";
import Loader from "../ui/Loader";
import { EmphFont, alertColor, primaryColor, secondaryColor } from "../ui/Styles";
import { TabSelectionHeight } from "../ui/TabSelection";
import { TitleBarHeight } from "../ui/TitleBar";

const TestRenderResultFailStyle = {
    color: alertColor,
    fontWeight: "bold",
}

const TestRenderResultStyle = {
    backgroundColor: secondaryColor,
    zIndex: 1,
    marginLeft: "auto",
}

const TestRenderModalRequestStyle = {
    position: "fixed",
    top: TitleBarHeight,
    zIndex: 500,
    bottom: TabSelectionHeight,
    backdropFilter: "blur(2px) contrast(60%)",
    left: 0,
    right: 0,
    display: "flex",
}

const TestRenderModalRequestCardContainerStyle = {
    margin: "auto",
    width: "85vw",
    maxHeight: "100%",
}

export const TestAdvices = {
    checkIdDefinition: "advices.checkIdDefinition",
    changeBatteryOrCheckPowerCables: "advices.changeBatteryOrCheckPowerCables",
    changeBatteryOrCheckPowerCablesOrCheckCanCables: "advices.changeBatteryOrCheckPowerCablesOrCheckCanCables",
    checkComoduleConnection: "advices.checkComoduleConnection",
    checkASIConnection: "advices.checkASIConnection",
    checkFrontLightConnection: "advices.checkFrontLightConnection",
    checkRearLightConnection: "advices.checkRearLightConnection",
    checkLockMotorConnection: "advices.checkLockMotorConnection",
    checkLockConnection: "advices.checkLockConnection",
    checkBuzzerConnection: "advices.checkBuzzerConnection",
    checkASIPedalConnection: "advices.checkASIPedalConnection",
    checkASIMotorConnection: "advices.checkASIMotorConnection",
}

export class Test {
    constructor() {
        this.reset();        
    }

    reset() {
        this.result = "N/A";
        this.cancel = false;
        this.fail = false;
        this.active = false;
        this.advices = [];
    }

    getAdvices() {
        return this.advices;
    }

    getResult() {
        if (this.cancel) {
            return "Cancelled";
        }
        else {
            return this.result;
        }
    }

    isFailed() {
        return this.cancel || this.fail;
    }

    isActive() {
        return this.active;
    }

    getModule() {
        return "?";
    }

    getName() {
        return "?";
    }

    getModal() {
        return null;
    }

    setCancelled() {
        this.advices = [];
        this.cancel = true;
        this.active = false;
    }

    setActive(active) {
        this.active = active;
    }

    async regularCheck(max_time_ms, check) {
        const start = Date.now();
        let last_res = null;
        while (Date.now() - start < max_time_ms) {
            const res = await check();

            if (res === true) {
                return;
            }
            else if (res !== false) {
                last_res = res;
            }

            if(this.cancel) {
                this.result = "Cancelled";
                this.fail = true;
                return;
            }

            await new Promise((resolve, reject) => { setTimeout(() => { resolve(); }, 250); });
        }

        if (last_res !== null) {
            this.fail = true;
            if(last_res.message) {
                this.result = last_res.message;
            }
            else {
                this.result = last_res;
            }
        }
    }

    async run(forceUpdate) {
        this.result = "Running...";
        forceUpdate();

        let p = new Promise((resolve, reject) => { setTimeout(() => { resolve(); }, 2000); });
        await p;
        this.result = "Not implemented";
    }

    render(index, translate) {
        let result;

        let resultStyle = TestRenderResultStyle;
        if (this.isFailed()) {
            resultStyle = { ...TestRenderResultFailStyle, ...resultStyle };
        }

        if (this.active) {
            result = <Loader/>;
        }
        else {
            result = <span style={resultStyle}>{translate(this.getResult())}</span>
        }

        let side_color = primaryColor;
        if(this.active) {
            side_color = primaryColor
        }
        if(this.isFailed()) {
            side_color = alertColor;
        }


        let modalRender;
        if(this.active) {
            const modal = this.getModal(translate);        
            if(modal !== null) {
                const cancel = () => {
                    this.setCancelled();
                }

                modalRender = (
                    <div style={TestRenderModalRequestStyle} key={"modal" + index}>
                        <div style={TestRenderModalRequestCardContainerStyle}>
                            <Card title={translate(this.getName())}>
                                <div>
                                    {modal}
                                    <div style={{display: 'flex', justifyContent: 'center', marginTop:'0.5em'}}>
                                        <ButtonGroup labels={[{render: translate("Cancel"), danger: true,     onClick: () => {cancel();}}]} />
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>
                );
            }
        }

        return [
            <div key={"lbl" + index} style={{display: "flex", flexDirection: "row", marginBottom: "1.5em"}}>
                <div style={{borderLeft: "2px solid " + side_color, marginLeft: "0.5em", marginRight: "1em"}}>

                </div>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <span style={{fontSize: "medium", ...EmphFont}}>{translate(this.getModule())} - {translate(this.getName())}</span> 
                    <span style={{marginTop: "0.5em", fontStyle: "italic"}}>{result}</span>
                </div>
            
            </div>,
            modalRender      
        ];     
    }
}