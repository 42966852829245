import { useState } from "react";
import ButtonGroup from "./ButtonGroup";
import { useTranslation } from "react-i18next";

function YesNoToggle(props) {
   const [selected, setSelected] = useState(props.default);
   const {t} = useTranslation();

   const handleChange = (value) => {
      props.onChange(value);
      setSelected(value);
   }

   return (
      <div style={{display: "flex", justifyContent: "center"}}>
         <ButtonGroup labels={[
            {render: t("No"), selected: selected === false, onClick: () => handleChange(false)},
            {render: t("Yes"), selected: selected === true, onClick: () => handleChange(true)},
         ]}/>
      </div>
   );
 
}

export default YesNoToggle;
