import battery from "../devices/battery";
import lock from "../devices/lock";
import { TurnBatteryOn } from "./Battery";
import { Test, TestAdvices } from "./Test";

class TestLockCls extends Test {
    getModule() {
        return "Lock";
    }
}

class CheckLockPowerCls extends TestLockCls {
    async run(forceUpdate) {
        if(!TurnBatteryOn.isTestValid()) {
            this.fail = true;
            this.result = "Skipped";
            return;
        }

        await this.regularCheck(10000, async () => {
            battery.generateHeartbeat(0, true);
            battery.generateHeartbeat(1, true);

            const state = lock.getState();

            if (state.state !== "offline") {
                this.result = "OK";
                this.fail = false;
                this.advices = [];
                return true;
            }
            else {
                this.result = {key: "lock.invalidState", data: {got: state.state}};
                this.fail = true;
                this.advices = [TestAdvices.checkLockConnection];
                return false;
            }
        })
    }

    getName() {
        return "Check heartbeat";
    }

    isTestValid() {
        return this.result === "OK";
    }
}

class CheckLockSequenceCls extends TestLockCls {
    constructor(again) {
        super();
        this.again = again;
    }

    async run(forceUpdate) {
        if(!TurnBatteryOn.isTestValid() || !CheckLockPower.isTestValid()) {
            this.fail = true;
            this.result = "Skipped";
            return;
        }

        battery.generateHeartbeat(0, true);
        battery.generateHeartbeat(1, true);

        lock.resetDoneFlag();
        for(let i = 0; i < 5; i++) {
            lock.setLock(true); 
            await new Promise((resolve) => setTimeout(resolve, 500));
        }

        await this.regularCheck(15000, async () => {
            const state = lock.getState();

            if(state.errors.length > 0) {                      
                this.result = ["lock.errors", ...state.errors.map((item) => 'lock.error_list.' + item)];
                this.fail = true;
                return false; 
            }

            if(state.done !== true) {
                this.result = "lock.notFinished";
                this.fail = true;
                this.advices = [TestAdvices.checkLockMotorConnection];
                return false;
            }

            if (state.state === "Locked") {
                this.result = "OK";
                this.advices = [];
                this.fail = false;
                return true;
            }
            else {
                this.result = {key: "lock.invalidState", data: {got: state.state}};
                this.advices = [TestAdvices.checkLockMotorConnection];
                this.fail = true;
                return false;
            }
        })
    }

    getName() {
        return "Lock sequence" + (this.again ? " again" : "");
    }

    isTestValid() {
        return this.result === "OK";
    }
}

class CheckUnlockSequenceCls extends TestLockCls {
    constructor(again) {
        super();
        this.again = again;
    }

    async run(forceUpdate) {
        if(!TurnBatteryOn.isTestValid() || !CheckLockPower.isTestValid()) {
            this.fail = true;
            this.result = "Skipped";
            return;
        }
        
        battery.generateHeartbeat(0, true);
        battery.generateHeartbeat(1, true);

        lock.resetDoneFlag();
        for(let i = 0; i < 5; i++) {
            lock.setLock(false); 
            await new Promise((resolve) => setTimeout(resolve, 500));
        }

        await this.regularCheck(15000, async () => {           
            const state = lock.getState();

            if(state.errors.length > 0) {
                this.result = ["lock.errors", ...state.errors.map((item) => 'lock.error_list.' + item)];
                this.fail = true;
                return false;
            }

            if(state.done !== true) {
                this.result = "lock.notFinished";
                this.advices = [TestAdvices.checkLockMotorConnection];
                this.fail = true;
                return false;
            }

            if (state.state === "Unlocked") {
                this.result = "OK";
                this.advices = [];
                this.fail = false;
                return true;
            }
            else {                
                this.result = {key: "lock.invalidState", data: {got: state.state}};
                this.advices = [TestAdvices.checkLockMotorConnection];
                this.fail = true;
                return false;
            }
        })
    }

    getName() {
        return "Unlock sequence" + (this.again ? " again" : "");
    }

    isTestValid() {
        return this.result === "OK";
    }
}


export const CheckLockPower = new CheckLockPowerCls();
export const CheckLockSequence = new CheckLockSequenceCls(false);
export const CheckUnlockSequence = new CheckUnlockSequenceCls(false);
export const CheckLockSequenceAgain = new CheckLockSequenceCls(true);