import battery from "../devices/battery";
import { Test, TestAdvices } from "./Test";

class TestBatteryCls extends Test {
    getModule() {
        return "battery.battery";
    }
}

class TurnBatteryOffCls extends TestBatteryCls {
    constructor(bat_id, again) {
        super();
        this.bat_id = bat_id;
        this.again = again;
    }

    async run(forceUpdate) {
        await this.regularCheck(15000, async () => {

            this.advices = [];
            this.result = "OK";
            this.fail = false;

            if(this.bat_id & 0x01) {
                battery.generateHeartbeat(0, false);
                battery.setEnable(0, false);
                const state = battery.getState(0);

                if(state.errors.length > 0) {
                    this.advices = [TestAdvices.changeBatteryOrCheckPowerCablesOrCheckCanCables];
                    return new Error("Errors: " + state.errors.join(", "));
                }
                
                if ((state.state === "Bus down") || (state.state === "offline")) {
                    //pass
                }
                else {
                    this.advices = [TestAdvices.changeBatteryOrCheckPowerCablesOrCheckCanCables];
                    this.result = {key: "battery.invalidState", data: {got: state.state, expected: "bus down"}};
                    this.fail = true;
                    return false;
                }
            }

            if(this.bat_id & 0x02) {
                battery.generateHeartbeat(1, false);
                battery.setEnable(1, false)
                const state = battery.getState(1);

                if(state.errors.length > 0) {
                    this.advices = [TestAdvices.changeBatteryOrCheckPowerCablesOrCheckCanCables];
                    return new Error("Errors: " + state.errors.join(", "));
                }
                
                if ((state.state === "Bus down") || (state.state === "offline")) {
                    //pass
                }
                else {
                    this.advices = [TestAdvices.changeBatteryOrCheckPowerCablesOrCheckCanCables];
                    this.result = {key: "battery.invalidState", data: {got: state.state, expected: "bus down"}};
                    this.fail = true;
                    return false;
                }
            }

            this.advices = [];
            this.result = "OK";
            this.fail = false;
            return true;
        })
    }

    getName() {
        return "Set off" + (this.again ? " again" : "");
    }

    isTestValid() {
        return this.result === "OK";
    }
}


class TurnBatteryOnCls extends TestBatteryCls {
    constructor(bat_id) {
        super();
        this.bat_id = bat_id;
    }


    async run(forceUpdate) {
        await this.regularCheck(10000, async () => {  
            if(this.bat_id & 0x01) {                
                battery.generateHeartbeat(0, true);
                battery.setEnable(0, true);
            }
            if(this.bat_id & 0x02) {          
                battery.generateHeartbeat(1, true);
                battery.setEnable(1, true);
            }

            this.fail = false;
            
            if(this.bat_id & 0x01) {
                const state = battery.getState(0);

                if(state.errors.length > 0) {
                    this.advices = [TestAdvices.changeBatteryOrCheckPowerCables];
                    return new Error("Errors: " + state.errors.join(", "));
                }
                
                if (state.state === "Active") {
                    this.advices = [];
                    this.result = "OK";
                    this.fail = false;
                    await new Promise((resolve) => setTimeout(resolve, 3000));
                    return true;
                }
                else {
                    this.advices = [TestAdvices.changeBatteryOrCheckPowerCables];
                    this.result = {key: "battery.invalidState", data: {got: state.state, expected: "active"}};
                    this.fail = true;
                    return false;
                }
            }
            if(this.bat_id & 0x02) {
                const state = battery.getState(1);

                if(state.errors.length > 0) {
                    this.advices = [TestAdvices.changeBatteryOrCheckPowerCables];
                    return new Error("Errors: " + state.errors.join(", "));
                }
                
                if (state.state === "Active") {
                    this.advices = [];
                    this.result = "OK";
                    this.fail = false;
                    await new Promise((resolve) => setTimeout(resolve, 3000));
                    return true;
                }
                else {
                    this.advices = [TestAdvices.changeBatteryOrCheckPowerCables];
                    this.result = {key: "battery.invalidState", data: {got: state.state, expected: "active"}};
                    this.fail = true;
                }
            }

            return !this.fail;
        })
    }

    getName() {
        return "Set on";
    }

    isTestValid() {
        return this.result === "OK";
    }
}

export const TurnBatteryOff = new TurnBatteryOffCls(3, false);
export const TurnBatteryOffAgain = new TurnBatteryOffCls(3, true);
export const TurnBatteryOn = new TurnBatteryOnCls(3);