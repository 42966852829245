import { alertColor, primaryColor, secondaryColor } from "./Styles";

const CardStyle = {  
}

const CardHeaderStyle = {  
  color: secondaryColor,
  backgroundColor: primaryColor,
  borderRadius: "10px 10px 0 0",
  display: "flex",
}

const CardFooterStyle = {
  borderRadius: "0 0 10px 10px",
  backgroundColor: primaryColor,
  color : secondaryColor,
  display: "flex",
}

const CardBodyNoFooterStyle = {
  borderRadius: "0 0 10px 10px",
}
const CardAlertHeaderStyle = {  
    backgroundColor: alertColor,
    color: "white",
}

const CardHeaderInfoStyle = {
  fontSize: "0.6em",
  marginTop: "0.25em",
  marginRight: "0.5em",
  marginBottom: "auto",
  marginLeft: "auto",
}

const CardHeaderTitleStyle = {
  margin: "10px",
  marginRight: "auto",
}

const CardBodyStyle = {  
  color: primaryColor,
  backgroundColor: secondaryColor,
  padding: "10px",
  overflow: "hidden",
}

const CardAlertBodyStyle = {
  backgroundColor: alertColor,
}

function Card(props) {
  let bodystyle = CardBodyStyle;
  let headerstyle = CardHeaderStyle;
  let footerstyle = CardFooterStyle;

  if(props.alert) {
    bodystyle = {...bodystyle, ...CardAlertBodyStyle};    
  }

  if(props.alertTitle) {
    headerstyle = {...headerstyle, ...CardAlertHeaderStyle};
    footerstyle = {...footerstyle, ...CardAlertHeaderStyle};
  }

  let footer = null;
  if(props.footer) {
    footer = <div style={footerstyle}>{props.footer}</div>;
  }
  else {
    bodystyle = {...bodystyle, ...CardBodyNoFooterStyle};
  }

  return (
    <div style={CardStyle}>
      <div style={headerstyle}><span style={CardHeaderTitleStyle}>{props.title}</span><span style={CardHeaderInfoStyle}>{props.info}</span></div>
      <div style={bodystyle}>{props.children}</div>
      {footer}
    </div>
  );
}

export default Card;
