import { alertColor, primaryColor, secondaryColor } from "./Styles";

function ButtonGroup(props) {
  const buttons = props.labels.map((label, index) => {
    let style = {...ButtonStyle};

    if(label.selected) {
      style = {...style, ...SelectedButtonStyle};
    }

    if(index === 0) {
      style = {...style, ...FirstButtonStyle};
    }

    if(index === (props.labels.length - 1)) {
      style = {...style, ...LastButtonStyle};
    }

    if(label.danger) {
      style = {...style, ...DangerButtonStyle};
    }

    if(label.disabled) {
      style = {...style, ...DisabledButtonStyle};
    }

    if(props.small) {
      style = {...style, ...SmallButtonStyle};
    }

    return (
      <span key={index} style={style} onClick={() => label.onClick()}>{label.render}</span>
    );
  });
  return (
    <div style={ButtonContainerStyle}>{buttons}</div>
  );
}

const ButtonContainerStyle = {
  display: "flex",
  margin: "0.25em",
}

const ButtonStyle = {
  backgroundColor: secondaryColor,
  color: primaryColor,
  border: "1px solid " + primaryColor,
  borderLeft: "none",
  padding: "10px",
  minWidth: "0px",
  textAlign: "center",
  cursor: "pointer",
}

const FirstButtonStyle = {  
  borderLeft: ButtonStyle.border,
  borderTopLeftRadius: "10px",
  borderBottomLeftRadius: "10px",
}
const LastButtonStyle = {  
  borderTopRightRadius: "10px",
  borderBottomRightRadius: "10px",
}

const SelectedButtonStyle = {
  backgroundColor: primaryColor,
  color: "white",
}

const DangerButtonStyle = {
  color: "white",
  backgroundColor: alertColor,
  border: "1px solid " + alertColor,
  borderLeft: "1px solid " + alertColor,
}

const DisabledButtonStyle = {
  opacity: 0.2,
}

const SmallButtonStyle = {
  padding: "5px",
  fontSize: "small",
}

export default ButtonGroup;
