import { ControllerViewer } from "./Controller";
import TitleBar, { TitleBarHeight } from "./ui/TitleBar";
import Logger from "./templates/Logger";
import TabSelection, { TabSelectionHeight, TabSelectionPage } from "./ui/TabSelection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import Manual from "./templates/Manual";
import Automatic from "./templates/Automatic";
import Version from "./ui/Version";
import i18n from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './i18n/en';
import fr from './i18n/fr';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: en,
      },
      fr: {
        translation: fr,
      }
    },
    fallbackLng: "en",

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });


const AppMainStyle = {
  marginTop: TitleBarHeight,
  marginBottom: TabSelectionHeight,
  
  display: "flex",
  flexDirection: "column",
  padding: "1em",
  gap: "1em",
}

function App() {
  //const [page, setPage] = useState('manual');
  const [page, setPage] = useState('automatic');
  const { t } = useTranslation();

  return (
    <div className="App">
      <ControllerViewer>
        <header className="App-header">
          <TitleBar />       
        </header>
        <main style={AppMainStyle}>
          <div style={(page === "logger") ?  {} : {display: 'none'}}>
            <Logger />
            <Version />
          </div>
          <div style={(page === "manual") ?  {} : {display: 'none'}}>
            <Manual />
            <br />
            <Version />
          </div>
          <div style={(page === "automatic") ?  {} : {display: 'none'}}>
            <Automatic />
          </div>          
        </main>
        <footer >
          <TabSelection>
            <TabSelectionPage title={t("manual")} icon={<FontAwesomeIcon icon="fa-solid fa-pen-to-square" />} onClick={() => setPage('manual')} active={page === 'manual'}/>
            <TabSelectionPage title={t("automatic")} icon={<FontAwesomeIcon icon="fa-solid fa-list-check" />} onClick={() => setPage('automatic')} active={page === 'automatic'}/>
            <TabSelectionPage title={t("can_viewer")} icon={<FontAwesomeIcon icon="fa-solid fa-network-wired" />} onClick={() => setPage('logger')} active={page === 'logger'}/>
          </TabSelection>
        </footer>
      </ControllerViewer>
    </div>
  );
}

export default App;
