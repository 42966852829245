const LANG_FR = {
   //tab titles
   "manual": "Manual",
   "automatic": "Automatique",
   "can_viewer": "Traces CAN",

   //controller
   "scanBox": "Scannez votre box de mainteannce",
   "connecting": "Connexion...",

   //actions
   "cancel": "Annuler",
   "OK": "OK",
   "Yes": "Oui",
   "No": "Non",
   "actions": "Actions",

   //can viewer
   "CANMessage": "Message CAN",

   //manuals
   "asi": {
        "state": "État",
        "enable": "Actif",
        "disable": "Désactivé",
        "offline": "Désactivé",
        "assistLevel": "Assistance",
        "frontLight": "Lumière avant",
        "speed": "Vitesse",
        "pedal": "Pédalier",
        "motor": "Moteur",
        "wheel": "Roue",
        
    },

    "battery": {
        "state": "État",
        "on": "Actif",
        "off": "Désactivé",
        "SOC": "SOC",
        "SN": "SN",
        "temperature": "Température",
        "battery": "Batterie",
    },

    "comodule": {
        "IOTModule": "Module IOT",
        "state": "État",
        "lock": "Verrouiller",
        "unlock": "Déverrouiller",
        "lockModule": "Cadena",
        "ID": "ID",
        "SOC": "SOC",
        "cellular": "Cellulaire",
        "Firmware": "Firmware",
    },

    "lock": {
        "motor": "Moteur",
        "lock": "Verrouiller",
        "unlock": "Déverrouiller",
        "bootloader": "Bootloader",
        "enable": "Actif",
        "disable": "Désactivé",
        "firmware": "Firmware",
        "hardware": "Hardware",
        "enabled": "Activé",
        "disabled": "Désactivé",
        "lockModule": "Cadena",
    },


    "auto": {
        "start": "DÉMARRAGE",
        "stop": "ARRÊT",
        "stopping": "ARRÊT EN COURS...",
        "Cancel": "Annuler",
        "Cancelled": "Annulé",
        "notranslate": "{{value}}",
        "Skipped": "Test non effectué",

        "test": "Test",
        "success": "Réussite",
        "failure_one": "{{count}} échec",
        "failure_other": "{{count}} échecs",
        "results": "Résultats",
        "OK": "OK",
        "N/A": "N/A",

        "Bike": "Vélo",
        "Scan QRcode": "Scannez le QRcode du vélo",
        "Comodule ID": "ID Comodule",
        "ASI": "ASI",
        "Lights": "Lumières",
        "Enable": "Activer",
        "Check motor": "Vérification moteur",
        "Battery": "Batterie",
        "Set off": "Éteindre",
        "Set off again": "Éteindre à nouveau",
        "Set on": "Allumer",
        "Verify IDs": "Vérification des IDs",
        "Frame ID": "ID du cadre",
        "Buzzer": "Buzzer",
        "Comodule": "Comodule",
        "Lock": "Cadena",
        "Check heartbeat":  "Vérification heartbeat",
        "Lock sequence": "Sequence de verrouillage",
        "Lock sequence again": "Séquence de verrouillage à nouveau",
        "Unlock sequence": "Séquence de déverrouillage",
        "Unlock sequence again": "Séquence de déverrouillage à nouveau",
        "Check lock bit": "Vérification du bit de verouillage",
        "Check unlock bit": "Vérification du bit de déverouillage",

        "comoduleNoAnswer": "Pas de réponse de comodule",
        "NO_QR_CODE": "Qrcode {{target}} introuvable",
        "ERR_FRAME_ID": "ID du cadre invalide. Attendu : {{target}}",
        "ERR_COMODULE_ID": "ID Comodule invalide. Attendu: {{target}}",
        "ERR_UPLOAD": "Impossible d'uploader le fichier",
        
        "bike": {
            "noQrCode": "Pas de Qrcode scanné",
            "scanBikeQrCode": "Scannez le QRcode du vélo",
            "scanQrCode": "Scan QRcode",
            "noFrameId": "Pas d'ID du cadre",
            "frameID": "ID du cadre",
            "frameIDPicture": "Photo de l'ID du cadre",
            "enterBikeFrameID": "Entrez l'ID du cadre",
            "enterBikeFrameIDLastDigits": "Entrez les derniers chiffres de l'ID du cadre",
            "comoduleID": "ID Comodule",
            "comoduleShakeToWakeUp": "Secouez le vélo pour réveiller le comodule",
            "verifyIDs": "Verififcation des IDs",
            "backendNotResponding": "Pas de réponse du backend",
            "scanFrameId": "Photo de l'ID du cadre",
            "uploadIDs": "Upload des idenfitifiants",

            "checkFrontLight": "Lumière avant",
            "isFrontLightBlinking": "Est ce que la lumière avant clignote :",
            "frontLightNotWorking":  "La lumière avant ne fonctionne pas",
            "checkRearLight": "Lumière arrière",
            "isRearLightActive": "Est ce que la lumière arrière est allumée :",
            "rearLightNotWorking":  "La lumière arrière ne fonctionne pas",
        },

        "battery": {
            "battery": "Batterie",
            "invalidState": "L'état est {{got}} au lieu de {{expected}}",
        },

        "lock": {
            "invalidState": "État '{{got}}' invalide",
            "notFinished": "Le cadena n'a pas fini son mouvement",
            "errors": "Erreurs: ",
            "error_list": {
                "Pin status error": "Erreur de lecture des pins du cadena",
                "HBridge error": "Pont en H hors service",
            },
        },

        "comodule": {
            "offline": "Comodule est hors ligne",
            "invalidHeartbeat": "Lock heartbeat est {{value}}",
            "invalidStateInformation": "State information est {{value}}",
            "invalidPowerOn": "Power on est {{value}}",
            "invalidBatteryState": "L'état de la batterie est {{value}}",
            "batteryError": "La batterie est en erreur",
            "lockError": "Le cadena est en erreur",
            "ASIError": "l'ASI est en erreur",
            "invalidLockState": "L'état du cadena est '{{value}}",
            "invalidASIState": "L'état de l'ASI est  {{value}}",
            "invalidASIAssist": "Le niveau d'assistance est {{value}}",
            "invalidASILight": "L'état de la commande de la lumière est {{value}}",
            "badBuzzer": "Le buzzer ne fonctionne pas",
            "isBuzzerActive": "Est ce que le buzzer est actif :",

            "errors": "Erreurs: ",
            "error_list": {
                "Battery error": "Erreur batterie",
                "ASI error": "Erreur ASI",
                "Lock error": "Erreur cadena",
                "Assist level change error": "Erreur de changement d'assistance",
            },
        },

        "asi": {
            "invalidState": "L'état est {{value}}",
            "notOnline": "L'ASI n'est pas allumé",
            "notWorking": "l'ASI ne fonctionne pas",
            "motorNotWorking": "Le moteur ne fonctionne pas",
            "turnPedalUntilMotorStarts": "Faites tourner le pédalier jusqu'à ce que le moteur tourne"
        },

        "advices": {
            "header": "Certains tests ont échoués. Voici quelques conseils pour les corriger : ",
            "checkIdDefinition": "Vérifiez l'association entre le vélo et le comodule",
            "changeBatteryOrCheckPowerCables": "Changez la batterie ou vérifiez les câbles d'alimentation",
            "changeBatteryOrCheckPowerCablesOrCheckCanCables": "Changez la batterie, vérifiez les câbles d'alimentation ou vérifiez les câbles CAN",
            "checkComoduleConnection": "Vérifiez le câble CAN du comodule",
            "checkASIConnection": "Vérifiez les câbles d'alimentation et CAN de l'ASI",
            "checkFrontLightConnection": "Vérifiez le câble de la lumière avant",
            "checkRearLightConnection": "Vérifiez le câble de la lumière arrière",
            "checkLockMotorConnection": "Vérifiez le câble du moteur du cadena",
            "checkLockConnection": "Vérifiez le câble CAN du cadena",
            "checkBuzzerConnection": "Vérifiez le câble du buzzer",
            "checkASIPedalConnection": "Vérifiez le câble du pédalier",
            "checkASIMotorConnection": "Vérifiez le câble du moteur de la roue",
        },

        "conf": {
            "title": "Configuration",
            "testSelection": "Types de tests :",
            "maintenance": "Maintenance",
            "assembly": "Assemblage",
        },    
    },
}

export default LANG_FR;