import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import Card from "../ui/Card";
import { CardBackgroundAlertStyle, ManualCardInfoErrorStyle, ManualCardInfoLeftStyle, ManualCardInfoRightStyle, ManualCardStyle, ManualCardTitleStyle } from "../templates/Manual";
import Separator from "../ui/Separator";
import ActionList from "../ui/ActionList";
import { Controller } from "../Controller";
import { useTranslation } from "react-i18next";

class ASICls {
    constructor() {
        this.reset();
        setInterval(() => this.checkState(), 100);
        Controller.subscribeToCanMessage((can) => this.parseCanMessage(can));
    }

    checkState() {
        if(Date.now() - this.lastPacket > 2500) {
            this.reset();
        }
    }

    reset() {
        this.state = 'offline';
        this.errors = [];
        this.vehiSpeed = 0;
        this.pedalRPM = 0;
        this.motorRPM = 0;
        this.wheelRPM = 0;
        this.assistLevel = 0;
        this.headlight = null;
    }

    getState() {
        return {
            state: this.state,
            vehiSpeed: this.vehiSpeed,
            pedalRPM: this.pedalRPM,
            motorRPM: this.motorRPM,
            wheelRPM: this.wheelRPM,
            assistLevel: this.assistLevel,
            headlight: this.headlight,
            errors: this.errors,
        }
    }

    setEnable(enable) {
        if(enable) {
            Controller.sendCanMessage(0x00, [0x01, 0x2A]);
        }
        else {
            Controller.sendCanMessage(0x00, [0x02, 0x2A]);
        }
    }

    setHeadlight(enable) {
        if(enable) {
            Controller.sendCanMessage(0x32A, [this.assistLevel, 0x00, 0x00, 0x00, 0x02, 0x00, 0x00, 0x00]);
        }
        else {
            Controller.sendCanMessage(0x32A, [this.assistLevel, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00]);
        }
    }

    setAssistLevel(level) {
        Controller.sendCanMessage(0x32A, [level, 0x00, 0x00, 0x00, this.headlight ? 0x02 : 0x00, 0x00, 0x00, 0x00]);
    }

    parseCanMessage(data) {
        const view = new DataView(new Uint8Array(data.data).buffer);


        const check_errors = (error_0, error_1) => {
            const errors = [];

            const errors_0_list = [
                "Ctrl overvoltage",
                "Phase overcurrent",
                "Current sensor calib.",
                "Current sensor overcurrent",
                "Ctrl overtemperature",
                "Motor hall sensor",
                "Ctrl undervoltage",
                "POST static gating test",
                "Network com timeout",
                "Inst. phase overcurrent",
                "Motor overtemperature",
                "Throttle voltage",
                "Inst. ctrl overvoltage",
                "Internal error",
                "Post dynamic gating test",
                "Inst. undervoltage",
            ];
            const errors_1_list = [
                "Param CRC",
                "Current scaling",
                "Voltage scaling",
                "Headlight undervoltage",
                "Reserved",
                "CAN",
                "Hall stall",
                "Bootloader",
                "Param 2 CRC",
                "Hall vs. sensorless",
                "Dyn. torque sensor voltage",
                "Dyn. torque static voltage",
                "Remote CAN fault",
                "Flash code 4,6",
                "Flash code 4,7",
                "Flash code 4,8",
            ];

            for(let i = 0; i < errors_0_list.length; i += 1) {
                if(error_0 & (1 << i)) {
                    errors.push(errors_0_list[i]);
                }
            }
            for(let i = 0; i < errors_1_list.length; i += 1) {
                if(error_1 & (1 << i)) {
                    errors.push(errors_1_list[i]);
                }
            }

            this.errors = errors;
        }

        if(data.id === 0x0AA) {
            this.lastPacket = Date.now();

            if(this.state === 'offline') {
                this.state = 'online';
            }

            check_errors(view.getUint16(0, true), view.getUint16(2, true));
        }
        else if(data.id === 0x1AA) {
            this.lastPacket = Date.now();

            if(this.state === 'offline') {
                this.state = 'online';
            }

            this.vehiSpeed = view.getInt16(2, true) / 256.0;
        }
        else if(data.id === 0x2AA) {
            this.lastPacket = Date.now();

            if(this.state === 'offline') {
                this.state = 'online';
            }

            this.pedalRPM = view.getInt16(0, true) / 64.0;
        }
        else if(data.id === 0x3AA) {
            this.lastPacket = Date.now();

            if(this.state === 'offline') {
                this.state = 'online';
            }

            this.motorRPM = view.getInt16(0, true) / 1.0;
            this.wheelRPM = view.getInt16(2, true) / 16.0;
        }
        else if(data.id === 0x4AA) {
            this.lastPacket = Date.now();

            if(this.state === 'offline') {
                this.state = 'online';
            }

            this.assistLevel = view.getInt16(4, true) / 1.0;
            check_errors(view.getUint16(0, true), view.getUint16(2, true));
        }       
        else if(data.id === 0x32A) {
             this.headlight = (data.data[4] & 0x02) !== 0;
        }      
    }
}

const ASI = new ASICls();
export default ASI;


export function ASIViewer () {
    const [ASIState, setASIState] = useState(ASI.getState());
    const {t} = useTranslation();

    useEffect(() => {    
        const handler = setInterval(() => {
            setASIState(() => ASI.getState());
        }, 250);
        
        return () => {
            clearInterval(handler);
        };
    });

    const alert = (ASIState.state === "error") || (ASIState.state === "offline");

    const actions = [{
        title: t("asi.state"),
        buttons: [
            {render: t("asi.enable"), selected: ASIState.state !== "offline",       onClick: () => {ASI.setEnable(true)}},
            {render: t("asi.offline"), selected: ASIState.state === "offline",     onClick: () => {ASI.setEnable(false)}},
        ]
    },{
        title: t("asi.assistLevel"),
        buttons: [
            {render: "0", selected: ASIState.assistLevel === 0,      onClick: () => {ASI.setAssistLevel(0)}},
            {render: "1", selected: ASIState.assistLevel === 1,      onClick: () => {ASI.setAssistLevel(1)}},
            {render: "6", selected: ASIState.assistLevel === 6,      onClick: () => {ASI.setAssistLevel(6)}},
            {render: "9", selected: ASIState.assistLevel === 9,      onClick: () => {ASI.setAssistLevel(9)}},
        ]
    },{
        title: t("asi.frontLight"),
        buttons: [
            {render: t("asi.enable"), selected: ASIState.headlight,      onClick: () => {ASI.setHeadlight(true)}},
            {render: t("asi.disable"), selected: !ASIState.headlight,    onClick: () => {ASI.setHeadlight(false)}},
        ]
    }]

    let titleStyle = ManualCardTitleStyle;
    let errorStyle = ManualCardInfoErrorStyle;
    if(alert) {
        titleStyle = {...titleStyle, ...CardBackgroundAlertStyle};
        errorStyle = {...errorStyle, ...CardBackgroundAlertStyle};
    }

    let error;
    if(ASIState.errors.length > 0) {
        error = (
            <div style={errorStyle}>
                {ASIState.errors.map((item) => t('asi.errors.' + item)).join(', ')}
            </div>
        );
    }

    let title = t("asi.offline");
    if(ASIState.state !== "offline") {
        title = t("asi.assistLevel") + ": " + ASIState.assistLevel;
    }

    return (
        <Card title={<span><FontAwesomeIcon icon="fa-solid fa-gear" />&nbsp;&nbsp;ASI</span>} >
            <div style={ManualCardStyle}>
                <div style={titleStyle}>{title}</div>        
               {error}
                <div style={ManualCardInfoLeftStyle}>
                    <span>{t("asi.speed")}: {ASIState.vehiSpeed.toFixed(0)} km/h</span>
                    <span>{t("asi.pedal")}: {ASIState.pedalRPM.toFixed(0)} rpm</span>
                </div>
                <div style={ManualCardInfoRightStyle}>
                    <span>{t("asi.motor")}: {ASIState.motorRPM.toFixed(0)} rpm</span>
                    <span>{t("asi.wheel")}: {ASIState.wheelRPM.toFixed(0)} rpm</span>
                </div>

                <div style={{gridColumn: "1 / 3", gridRow: "4"}}>
                    <Separator title={t("actions")}/>
                    <ActionList labels={actions} />
                </div>                
            </div>
        </Card>
    );
};
  