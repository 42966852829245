import {QrScanner} from '@yudiel/react-qr-scanner';
import { TitleBarHeight } from './TitleBar';
import { TabSelectionHeight} from './TabSelection';

const max_width = '90vw - 10px - 10px - 10px'; //div padding = 10px
const max_height = '100vh - ' + TitleBarHeight + ' - ' + TabSelectionHeight + ' - 15em'; 
export const video_size_calc = 'calc(min('+max_width+', '+max_height+'))';

const QrScanVideoStyle = {

}

function QRScan(props) {
   return (
      <div style={{height: video_size_calc, width: video_size_calc, margin: 'auto'}}>
         <QrScanner 
            containerStyle={QrScanVideoStyle}
            onDecode={(result) => props.onResult(result)}
            onError={(error) => console.log(error?.message)}
            constraints={{facingMode: 'environment', aspectRatio: 1}}
         />
      </div>
   );
}

export default QRScan;
