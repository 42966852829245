import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { alertColor, primaryColor, secondaryColor } from "./Styles";
import { Controller } from "../Controller";
import { useEffect, useState } from "react";

export const TitleBarHeight = "44px";

const TitleBarStyle = {  
  backgroundColor: secondaryColor,
  display: 'flex',
  height: TitleBarHeight,
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1000,
}

const TitleBarLogoStyle = {
  margin: "5px",
  marginTop: "7px",
}

const TitleBarNameStyle = {
  fontFamily: 'Barlow',
  marginTop: "auto",
  marginBottom: "12px",
  marginLeft: "10px",
  textTransform: "uppercase",

  color: primaryColor,
}

const TitleBarOffStyle = {
  color: alertColor,
  margin: "auto",
  marginRight: "2em",
  cursor: "pointer",
}

function TitleBar() {
  const [state, setState] = useState(Controller.getState());
  useEffect(() => {    
      const controllerEvent = (state) => {
          setState(() => { return {...state}});
      }
  
      Controller.subscribeToEvent(controllerEvent);
      
      return () => {
        Controller.unsubscribeToEvent(controllerEvent);      
      };
  });
  
  let off;
  if(state.connected !== null) {
    off = <span style={TitleBarOffStyle} onClick={() => Controller.close()}><FontAwesomeIcon icon="fa-solid fa-power-off"/></span>
  }

  return (
    <div style={TitleBarStyle}>
      <img style={TitleBarLogoStyle} src="logo_full.png" alt="Pony Logo" />
      <span style={TitleBarNameStyle}>Maintenance</span>
      {off}
    </div>
  );
}

export default TitleBar;
