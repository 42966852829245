import { ASIViewer } from "../devices/ASI"
import { BatteryViewer } from "../devices/battery"
import { ComoduleViewer } from "../devices/comodule"
import { LockViewer } from "../devices/lock"
import { EmphFont, alertColor } from "../ui/Styles"

const ManualStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: '1em',
}

export const ManualCardStyle = {
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
}

export const CardBackgroundAlertStyle = {
  backgroundColor: alertColor,
}

export const ManualCardTitleStyle = {
  fontSize: "1.25em",
  gridColumn: "1 / 3",  
  gridRow: "1",
  marginTop: "-0.5em",
  marginLeft: "-0.5em",
  marginRight: "-0.5em",
  paddingTop: "0.5em",
  paddingBottom: "0.5em",
  width: "calc(100% + 1em)",
  textAlign: "center",

  ...EmphFont,
}

export const ManualCardInfoErrorStyle = {  
  gridColumn: "1 / 3",
  gridRow: "2",
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
  paddingBottom: "0.5em",
  marginBottom: "0.5em",
  marginLeft: "-2.5em",
  marginRight: "-2.5em",
  width: "calc(100% + 5em)",

  ...EmphFont,
}

export const ManualCardInfoLeftStyle = {  
  gridColumn: "1",
  gridRow: "3",
  display: "flex",
  flexDirection: "column",
  marginTop: "0.5em",
}

export const ManualCardInfoRightStyle = {  
  gridColumn: "2",
  gridRow: "3",
  display: "flex",
  flexDirection: "column",
  textAlign: "end",
  marginTop: "0.5em",
}

function Manual() {
  return (
    <section style={ManualStyle}>
      <ComoduleViewer />
      <BatteryViewer id={0} />
      <BatteryViewer id={1} />
      <LockViewer />
      <ASIViewer />
    </section>
  );
}

export default Manual;
