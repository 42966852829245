export const primaryColor = "#321e64";
export const secondaryColor = "rgb(204, 255, 255)";
export const alertColor = "#ff6e82";


export const EmphFont = {
    fontFamily: 'Barlow',
    textTransform: "uppercase",
}

export const MatterFont = {
    fontFamily: 'Matter',
    textTransform: "uppercase",
}