import ASI from "../devices/ASI";
import battery from "../devices/battery";
import comodule from "../devices/comodule";
import lock from "../devices/lock";
import YesNoToggle from "../ui/YesNoToggle";
import { TurnBatteryOn } from "./Battery";
import { GetComoduleID } from "./Bike";
import { CheckLockSequence, CheckUnlockSequence } from "./Lock";
import { Test, TestAdvices } from "./Test";

class ComoduleCls extends Test {
    
    getModule() {
        return "Comodule";
    }
}

class SetPassiveModeCls extends ComoduleCls {
    constructor(enable) {
        super();
        this.enable = enable;
    }

    async run(forceUpdate) {
        if(!GetComoduleID.isTestValid()) {
            this.fail = true;
            this.result = "Skipped";
            return;
        }

        this.result = "Not working yet";
    }

    getName() {
        if(this.enable) {
            return "Set passive mode";
        }
        else {
            return "Reset passive mode";
        }
    }
}

class ComoduleUnlockCls extends ComoduleCls {

    async run(forceUpdate) {
        if(!GetComoduleID.isTestValid() || !TurnBatteryOn.isTestValid()) {
            this.fail = true;
            this.result = "Skipped";
            return;
        }

        battery.generateHeartbeat(0, false);
        battery.generateHeartbeat(1, false);
        comodule.setLock(false);

        await this.regularCheck(60000, async () => {           
            const comodule_state = comodule.getState();
            const battery_state = [battery.getState(0), battery.getState(1)];
            const lock_state = lock.getState();
            const asi_state = ASI.getState();

            if(comodule_state.state !== "online") {
                this.result = "comodule.offline";
                this.advices = [TestAdvices.checkComoduleConnection];
                this.fail = true;
                return false;
            }

            if(comodule_state.lock_heartbeat !== 16) {
                this.result = {key: "comodule.invalidHeartbeat", data: {value: comodule_state.lock_heartbeat}};
                this.advices = [TestAdvices.checkLockConnection];
                this.fail = true;
                return false;
            }

            if((battery_state[0].state !== "Active") && (battery_state[1].state !== "Active")) {
                this.result = {key: "comodule.invalidBatteryState", data: {value: comodule_state.power_on}};
                this.advices = [TestAdvices.changeBatteryOrCheckPowerCablesOrCheckCanCables];
                this.fail = true;
                return false;
            }

            if((battery_state[0].errors.length > 0) || (battery_state[1].errors.length > 0)) {
                this.result = "comodule.batteryError";
                this.advices = [TestAdvices.changeBatteryOrCheckPowerCablesOrCheckCanCables];
                this.fail = true;
                return false;
            }

            if(lock_state.state !== "Unlocked") {
                this.result = {key: "comodule.invalidLockState", data: {value: lock_state.state}};
                this.advices = [TestAdvices.checkLockMotorConnection];
                this.fail = true;
                return false;
            }

            if(lock_state.errors.length > 0) {
                this.result = "comodule.lockError";
                this.advices = [TestAdvices.checkLockMotorConnection];
                this.fail = true;
                return false;
            }

            if(asi_state.state !== "online") {
                this.result = {key: "comodule.invalidASIState", data: {value: asi_state.state}};
                this.advices = [TestAdvices.checkASIConnection];
                this.fail = true;
                return false;
            }

            if(asi_state.errors.length > 0) {
                this.result = "comodule.ASIError";
                this.advices = [TestAdvices.checkASIConnection];
                this.fail = true;
                return false;
            }

            if(asi_state.assistLevel !== 6) {
                this.result = {key: "comodule.invalidASIAssist", data: {value: asi_state.state}};
                this.advices = [TestAdvices.checkASIConnection];
                this.fail = true;
                return false;
            }

            if(asi_state.headlight !== true) {
                this.result = {key: "comodule.invalidASILight", data: {value: asi_state.state}};
                this.advices = [TestAdvices.checkASIConnection];
                this.fail = true;
                return false;
            }

            if(comodule_state.power_on !== true) {
                this.result = {key: "comodule.invalidPowerOn", data: {value: comodule_state.power_on}};
                this.advices = [TestAdvices.checkComoduleConnection];
                this.fail = true;
                return false;
            }

            if(comodule_state.state_information !== 658) {
                this.result = {key: "comodule.invalidStateInformation", data: {value: comodule_state.state_information}};
                this.advices = [TestAdvices.checkComoduleConnection];
                this.fail = true;
                return false;
            }

            await new Promise((resolve) => { setTimeout(resolve, 5000); });

            this.result = "OK";
            this.advices = [];
            this.fail = false;
            return true;
        })
    }

    getName() {
        return "Unlock";
    }
}

class ComoduleLockCls extends ComoduleCls {
    async run(forceUpdate) {
        if(!GetComoduleID.isTestValid() || !TurnBatteryOn.isTestValid()) {
            this.fail = true;
            this.result = "Skipped";
            return;
        }

        battery.generateHeartbeat(0, false);
        battery.generateHeartbeat(1, false);
        comodule.setLock(false);

        await this.regularCheck(60000, async () => {           
            const comodule_state = comodule.getState();
            const battery_state = [battery.getState(0), battery.getState(1)];
            const asi_state = ASI.getState();

            if(comodule_state.state !== "online") {
                this.result = "comodule.offline";
                this.advices = [TestAdvices.checkComoduleConnection];
                this.fail = true;
                return false;
            }

            if(comodule_state.lock_heartbeat !== 17) {
                this.result = {key: "comodule.invalidHeartbeat", data: {value: comodule_state.lock_heartbeat}};
                this.advices = [TestAdvices.checkLockConnection];
                this.fail = true;
                return false;
            }

            if(
                ((battery_state[0].state !== "Bus down") && (battery_state[0].state !== "offline")) ||
                ((battery_state[1].state !== "Bus down") && (battery_state[1].state !== "offline"))
             ) {
                this.result = {key: "comodule.invalidBatteryState", data: {value: comodule_state.power_on}};
                this.advices = [TestAdvices.changeBatteryOrCheckPowerCablesOrCheckCanCables];
                this.fail = true;
                return false;
            }

            if(asi_state.state !== "offline") {
                this.result = {key: "comodule.invalidASIState", data: {value: asi_state.state}};
                this.advices = [TestAdvices.checkASIConnection];
                this.fail = true;
                return false;
            }

            if(comodule_state.state_information !== 257) {
                this.result = {key: "comodule.invalidStateInformation", data: {value: comodule_state.state_information}};
                this.advices = [TestAdvices.checkComoduleConnection];
                this.fail = true;
                return false;
            }
            
            if(comodule_state.errors.length > 0) {
                this.result = ["comodule.errors", ...comodule_state.errors.map((item) => 'comodule.error_list.' + item)];
                this.advices = [TestAdvices.checkComoduleConnection];
                this.fail = true;
                return false;
            }

            if(comodule_state.power_on !== false) {
                this.result = {key: "comodule.invalidPowerOn", data: {value: comodule_state.power_on}};
                this.advices = [TestAdvices.checkComoduleConnection];
                this.fail = true;
                return false;
            }

            this.result = "OK";
            this.fail = false;
            this.advices = [];
            return true;
        })
    }

    getName() {
        return "Lock";
    }
}
class ComoduleCheckLockBitCls extends ComoduleCls {
    constructor(shouldBeLocked) {
        super();
        this.shouldBeLocked = shouldBeLocked;
    }

    async run(forceUpdate) {       
        if(!GetComoduleID.isTestValid() || !TurnBatteryOn.isTestValid()) {
            this.fail = true;
            this.result = "Skipped";
            return;
        }

        if(this.shouldBeLocked) {
            if(!CheckLockSequence.isTestValid()) {
                this.fail = true;
                this.result = "Skipped";
                return;            
            }
        }
        else {
            if(!CheckUnlockSequence.isTestValid()) {
                this.fail = true;
                this.result = "Skipped";
                return;            
            }
        }
        

        await this.regularCheck(60000, async () => {           
            const comodule_state = comodule.getState();           

            if(this.shouldBeLocked) {
                if((comodule_state.lock_heartbeat & 0x01) !== 0x01) {
                    this.result = {key: "comodule.invalidHeartbeat", data: {value: comodule_state.lock_heartbeat}};
                    this.fail = true;
                    this.advices = [TestAdvices.checkLockMotorConnection];
                    return false;
                }
            }
            else {
                if((comodule_state.lock_heartbeat & 0x01) !== 0x00) {
                    this.result = {key: "comodule.invalidHeartbeat", data: {value: comodule_state.lock_heartbeat}};
                    this.fail = true;
                    this.advices = [TestAdvices.checkLockMotorConnection];
                    return false;
                }
            }
            
            this.result = "OK";
            this.fail = false;
            this.advices = [];
            return true;
        })
    }

    getName() {
        if(this.shouldBeLocked) {
            return "Check lock bit";
        }
        else {
            return "Check unlock bit";
        }
    }
}


class ComoduleBuzzerCls extends ComoduleCls {
    constructor() {
        super();
        this.buzzer = null;
    }
    
    reset() {
        super.reset();
        this.buzzer = null;
    }
        
    async run(forceUpdate) {
        let last_toogle = Date.now();

        if(!GetComoduleID.isTestValid()) {
            this.fail = true;
            this.result = "Skipped";
            return;
        }

        await this.regularCheck(Infinity, async () => {
            const delta = Date.now() - last_toogle;
            if(delta >= 1000) {
                comodule.buzzer();
                last_toogle = Date.now();
            }

            if(this.buzzer === true) {
                this.result = "OK";
                this.advices = [];
                this.fail = false;
                return true;
            }
            else if(this.buzzer === false) {
                this.result = "comodule.badBuzzer";
                this.advices = [TestAdvices.checkBuzzerConnection];
                this.fail = true;
                return true;
            }
            else {
                this.result = "comodule.badBuzzer";
                this.advices = [TestAdvices.checkBuzzerConnection];
                this.fail = true;
                return false;
            }            
        })
        return true;
    }

    getModal(translate) {
        const setBuzzer = (value) => {
            this.buzzer = value;
        };

        return (
            <div>
                <h1 style={{fontSize: "medium"}}>{translate("comodule.isBuzzerActive")}</h1>
                    <YesNoToggle default={this.horn} onChange={setBuzzer}/>
                <br />
            </div>
        );
    }

    getName() {
        return "Buzzer";
    }
}

export const SetPassiveMode = new SetPassiveModeCls(true);
export const ResetPassiveMode = new SetPassiveModeCls(false);
export const ComoduleUnlock = new ComoduleUnlockCls();
export const ComoduleLock = new ComoduleLockCls();
export const ComoduleCheckLockbit = new ComoduleCheckLockBitCls(true);
export const ComoduleCheckUnlockbit = new ComoduleCheckLockBitCls(false);
export const ComoduleBuzzer = new ComoduleBuzzerCls();