import { ASICheckMotor, ASITurnOn } from "./ASI";
import { TurnBatteryOff, TurnBatteryOffAgain, TurnBatteryOn } from "./Battery";
import { BikeCheckIdDefinition, GetFrameID, ScanQrCode, GetComoduleID, RearLight, FrontLight, GetFrameIDPicture, BikeUploadIds, GetFrameIDWithPreset, BikeUploadMaintenanceTestResult, BikeUploadAssemblyTestResult } from "./Bike";
import { ComoduleBuzzer, ComoduleCheckLockbit, ComoduleCheckUnlockbit } from "./Comodule";
import { CheckLockPower, CheckLockSequence, CheckLockSequenceAgain, CheckUnlockSequence } from "./Lock";
import "./Test";

export const TEST_LIST_MAINTENANCE = [
    ScanQrCode,
    GetFrameID,
    GetComoduleID,
    BikeCheckIdDefinition,
    //SetComoduleID,
    //SetPassiveMode,
    TurnBatteryOff,
    TurnBatteryOn,    
    RearLight,
    ComoduleBuzzer,
    CheckLockPower,    
    CheckLockSequence,
    ComoduleCheckLockbit,
    CheckUnlockSequence,
    ComoduleCheckUnlockbit,
    ASITurnOn,
    ASICheckMotor,
    FrontLight,
    CheckLockSequenceAgain,
    TurnBatteryOffAgain,
    //ResetPassiveMode,
    BikeUploadMaintenanceTestResult,
]

export const TEST_LIST_ASSEMBLY = [    
    //TurnBatteryOff,
    //TurnBatteryOn,
    ScanQrCode,
    GetFrameIDWithPreset('D04T'),
    GetComoduleID,
    //BikeUploadIds,    
    /*
    RearLight,
    ComoduleBuzzer,
    CheckLockPower,    
    CheckLockSequence,
    CheckUnlockSequence,
    ASITurnOn,
    ASICheckMotor,
    FrontLight,
    //TurnBatteryOffAgain,
    */

    BikeUploadAssemblyTestResult,
]