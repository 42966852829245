import { useState } from "react";
import { primaryColor, secondaryColor } from "./Styles";
import { useTranslation } from "react-i18next";

const InputStyle = {
   marginBottom: "0.5em", 
   marginTop: "0.5em", 
   display: "flex"
}

const InputFieldStyle = {
   color: primaryColor,
   border: "1px solid " + primaryColor,
   borderRight: "none",
   borderRadius: "10px 0 0 10px",
   padding: "0.5em",
   flexGrow: 1,
   width: "100%",
}

const OkButtonStyle = {
   padding: "0.5em 0.75em 0.5em 0.5em",
   backgroundColor: primaryColor,
   color: secondaryColor,
   border: "1px solid " + primaryColor,
   borderRadius: "0 10px 10px 0",
   cursor: "pointer",
}


function Input(props) {
   const [value, setValue] = useState("");
   const {t} = useTranslation();

   const handleChange = (event) => {
      setValue(event.target.value);  
   }

   const handleOk = () => {
      props.onResult(value);
   }

   const handleKeyDown = (event) => {
      if(event.key === "Enter") {
         handleOk();
      }
   }

   return (
      <div style={InputStyle}>         
         <input style={InputFieldStyle} type={props.input_type ?? "text"} placeholder={props.placeholder} value={value} onChange={handleChange} onKeyDown={handleKeyDown}/>
         <span style={OkButtonStyle} onClick={handleOk}>{t("OK")}</span>
      </div>
   );
 
}

export default Input;
