import { primaryColor, secondaryColor } from "./Styles";

const LoaderStyle = {
   width: "1em",
   height: "1em",
   display: "inline-block",
   marginBottom: "0.5em",
   marginLeft: "0.5em",

   backgroundColor: secondaryColor,

   border: "2px solid " + primaryColor,
   borderBottom: "2px solid " + secondaryColor,

   borderRadius: "50%",
   boxSizing: "border-box",
   animation: "rotation 1s linear infinite",
}

function Loader(props) {
   return (
      <span style={LoaderStyle}></span>
   );
 
}

export default Loader;
