import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import Card from "../ui/Card";
import { CardBackgroundAlertStyle, ManualCardInfoErrorStyle, ManualCardInfoLeftStyle, ManualCardInfoRightStyle, ManualCardStyle, ManualCardTitleStyle } from "../templates/Manual";
import Separator from "../ui/Separator";
import ActionList from "../ui/ActionList";
import { Controller } from "../Controller";
import { useTranslation } from "react-i18next";

class BatteryCls {
    constructor() {
        this.state = ['', ''];

        this.soc = ['?', '?'];
        this.sn = ['?', '?'];
        this.temp = ['?', '?'];
        this.errors = [[], []];
        this.lastPacket = [0, 0];

        this.reset();
        setInterval(() => this.checkState(), 100);
        Controller.subscribeToCanMessage((can) => this.parseCanMessage(can));
    }

    checkState() {
        if(Date.now() - this.lastPacket > 2500) {
            this.reset();
        }
    }

    reset() {
        this.state = ['offline', 'offline'];
        this.soc = ['?', '?'];
        this.sn = ['?', '?'];
        this.temp = ['?', '?'];
        this.errors = [[], []];
    }

    getState(id) {
        return {
            state: this.state[id],
            soc: this.soc[id],
            sn: this.sn[id],
            temp: this.temp[id],
            errors: this.errors[id],
        }
    }

    setEnable(id, enable) {
        if(id === 1) {
            if(enable) {
                Controller.sendCanMessage(0x321, [0x33, 0xCC, 0x33, 0xCC, 0x33, 0xCC, 0x33, 0xCC]);
            }
            else {
                Controller.sendCanMessage(0x322, [0x55, 0xBB, 0x55, 0xBB, 0x55, 0xBB, 0x55, 0xBB]);
            }
        }
        else {
            if(enable) {
                Controller.sendCanMessage(0x121, [0x33, 0xCC, 0x33, 0xCC, 0x33, 0xCC, 0x33, 0xCC]);
            }
            else {
                Controller.sendCanMessage(0x122, [0x55, 0xBB, 0x55, 0xBB, 0x55, 0xBB, 0x55, 0xBB]);
            }
        }
    }

    generateHeartbeat(id, enable) {
        Controller.sendGenericMessage({
            type: "heartbeat",
            id: id,
            enable: enable,
        });
    }

    parseCanMessage(data) {
        const view = new DataView(new Uint8Array(data.data).buffer);
        
        if((data.id === 0x1FF) || (data.id === 0x3FF)){
            const id = (data.id === 0x1FF) ? 0 : 1;

            this.lastPacket[id] = Date.now();

            switch(data.data[0]) {
                case 0x01:
                    this.state[id] = 'Protection';
                    break;
                case 0x02:
                    this.state[id] = 'Active';
                    break;
                case 0x04:
                    this.state[id] = 'Precharge';
                    break;
                case 0x08:
                    this.state[id] = 'Bus down';
                    break;
                case 0x10:
                    this.state[id] = 'BLE';
                    break;
                default:
                    this.state[id] = 'Unknown (' + data.data[0] + ')';
                    break;
            }                        
        }
        else if((data.id === 0x22A) || (data.id === 0x22B)){
            const id = (data.id === 0x22A) ? 0 : 1;

            this.lastPacket[id] = Date.now();

            if(this.state[id] === 'offline') {
                this.state[id] = 'online';
            }

            this.soc[id] = view.getInt16(0, true);
            this.temp[id] = view.getInt16(2, true);
        }    
        else if(data.id === 0x1FD) {
            if(data.data.length === 8) {
                const id = data.data[7] > 0 ? 1 : 0;
                this.sn[id] = 0;
                for(let i = 6; i >= 0; i -= 1) {
                    this.sn[id] *= 256;
                    this.sn[id] += data.data[i];
                }
            }        
        }
    }
}

const battery = new BatteryCls();
export default battery;


export function BatteryViewer ({id}) {
    const [batteryState, setBatteryState] = useState(battery.getState(id));
    const {t} = useTranslation();

    useEffect(() => {    
        const handler = setInterval(() => {
            setBatteryState(() => battery.getState(id));
        }, 250);
        
        return () => {
            clearInterval(handler);
        };
    });

    
    const alert = (batteryState.state === "Protection") || (batteryState.state === "offline") || (batteryState.state === "Bus down");

    const actions = [{
        title: t("battery.state"),
        buttons: [
            {render: t("battery.on"), selected: !alert,     onClick: () => {battery.setEnable(id, true); battery.generateHeartbeat(id, true)}},
            {render: t("battery.off"), selected: alert,     onClick: () => {battery.generateHeartbeat(id, false); battery.setEnable(id, false)}},
        ]
    }]
  
    let titleStyle = ManualCardTitleStyle;
    let errorStyle = ManualCardInfoErrorStyle;
    if(alert) {
        titleStyle = {...titleStyle, ...CardBackgroundAlertStyle};
        errorStyle = {...errorStyle, ...CardBackgroundAlertStyle};
    }

    let error;
    if(batteryState.errors.length > 0) {
        error = (
            <div style={errorStyle}>
                {batteryState.errors.map((item) => t('battery.errors.' + item)).join(', ')}
            </div>
        );
    }
  
    return (
        <Card title={<span><FontAwesomeIcon icon="fa-solid fa-car-battery" />&nbsp;&nbsp;{t('battery.battery')} {id}</span>} >
            <div style={ManualCardStyle}>
                <div style={titleStyle}>{batteryState.state}</div>        
               {error}
                <div style={ManualCardInfoLeftStyle}>
                    <span>{t('battery.SN')}: {batteryState.sn}</span>
                    <span>{t('battery.SOC')}: {batteryState.soc} %</span>
                </div>
                <div style={ManualCardInfoRightStyle}>                    
                    <span>{t('battery.temperature')}: {batteryState.temp} °C</span>
                </div>

                <div style={{gridColumn: "1 / 3", gridRow: "4"}}>
                    <Separator title={t("actions")}/>
                    <ActionList labels={actions} />
                </div>                
            </div>
        </Card>
    );
};
  